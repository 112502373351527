import { Injectable, ViewEncapsulation } from '@angular/core';
import { PopoverController, LoadingController, ModalController, AlertController, ActionSheetController, ToastController } from '@ionic/angular';
import { PopoverOptions, ModalOptions, LoadingOptions, AlertOptions, ActionSheetOptions, ToastOptions } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class PresentersService {

  _popover: any;
  _loader: any;
  _modal: any;
  _alert: any;
  _actionSheet: any;
  _toast: any;

  constructor(
    public loadingCtrl: LoadingController,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public actionSheetCtrl: ActionSheetController,
    public toastCtrl: ToastController
  ) { }

  async presentPopover(popOpts: PopoverOptions) {
    this._popover = await this.popoverCtrl.create(popOpts);
    // if (dismiss) {
    //   this._popover.onDidDismiss(dismiss);
    // }
    await this._popover.present();
    return this._popover.onDidDismiss();
  }

  async presentModal(modOpts: ModalOptions) {
    this._modal = await this.modalCtrl.create(modOpts);
    // if (dismiss) {
    //   this._modal.onDidDismiss().then(dismiss);
    // }
    await this._modal.present();
    return this._modal.onDidDismiss();
  }

  // async onDismissModal(dismiss) {
  //     return await this._modal.onDidDismiss(dismiss);
  // }

  async presentLoader(loadOpts: LoadingOptions) {
    this._loader = await this.loadingCtrl.create(loadOpts);
    // if (dismiss) {
    //   this._loader.onDidDismiss(dismiss);
    // }
    await this._loader.present();
    return this._loader.onDidDismiss();
  }

  async presentAlert(alertOpts: AlertOptions) {
    this._alert = await this.alertCtrl.create(alertOpts);
    // if (dismiss) {
    //   this._alert.onDidDismiss(dismiss);
    // }
    await this._alert.present();
    return this._alert.onDidDismiss();
  }

  async presentActionsheet(actionOpts: ActionSheetOptions) {
    this._actionSheet = await this.actionSheetCtrl.create(actionOpts);
    // if (dismiss) {
    //   this._actionSheet.onDidDismiss(dismiss);
    // }
    await this._actionSheet.present();
    return this._actionSheet.onDidDismiss();
  }

  async presentToast(toastOpts: ToastOptions) {
    this._toast = await this.toastCtrl.create(toastOpts);
    // if (dismiss) {
    //   this._toast.onDidDismiss(dismiss);
    // }
    await this._toast.present();
    return this._toast.onDidDismiss();
  }

  dismissPopover(obj?) {
    if (this._popover) {
      if (obj) {
        this._popover.dismiss(obj);
      } else {
        this._popover.dismiss();
      }
    }
  }

  dismissModal(obj?) {
    console.log(obj);
    if (this._modal) {
      if (obj) {
        this._modal.dismiss(obj);
      } else {
        this._modal.dismiss();
      }
    }
  }

  dismissLoader() {
    if (this._loader) {
      this._loader.dismiss();
    }
  }

  dismissAlert(obj?) {
    if (this._alert) {
      if (obj) {
        this._alert.dismiss(obj);
      } else {
        this._alert.dismiss();
      }
    }
  }

  dismissActionSheet(obj?) {
    if (this._actionSheet) {
      if (obj) {
        this._actionSheet.dismiss(obj);
      } else {
        this._actionSheet.dismiss();
      }
    }
  }

  dismissToast(obj?) {
    if (this._toast) {
      if (obj) {
        this._toast.dismiss(obj);
      } else {
        this._toast.dismiss();
      }
    }
  }
}
