import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MemberPermissionsComponent } from './member-permissions.component';
import { PipesModule } from '../../_pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    IonCustomScrollbarModule
  ],
  declarations: [MemberPermissionsComponent],
  exports: [MemberPermissionsComponent]
})
export class MemberPermissionsComponentModule {}
