import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { MeteorObservable } from 'meteor-rxjs';
import { PresentersService } from '../../_services/presenters.service';
import { ActivitiesService } from 'src/app/_services/activities.service';

@Component({
  selector: 'app-season-invites',
  templateUrl: './season-invites.component.html',
  styleUrls: ['./season-invites.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SeasonInvitesComponent implements OnInit {

  @Input() invites = [];
  @Input() userId = '';

  constructor(public presenters: PresentersService,
    public activities: ActivitiesService
    ) {

  }

  ngOnInit() {
    console.log(this.invites);
    console.log(this.userId);
  }

  close() {
    this.presenters.dismissModal();
  }

  accept(invite, userId) {
    console.log(invite);
    this.presenters.presentLoader({
      message: 'Accepting Organisation Invite...'
    });
    MeteorObservable.call('season.userAcceptInviteToSeason', invite._id, [userId]).subscribe(() => {
      this.presenters.dismissLoader();
      this.presenters.presentAlert({
        header: 'Invite Complete',
        message: 'Welcome to ' + invite.orgName + ' season ' + invite.name,
        buttons: ['Ok']
      });
      this.activities.refreshSeasonList();
      // this.activities.resetLogin();
    }, (err) => {
      this.presenters.dismissLoader();
      this.presenters.presentAlert({
        header: 'Invite Failed',
        message: 'Something happened, please try again later.' + err.message ,
        buttons: ['Ok']
      });
      console.log(err);
    });
  }

}
