import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NewPositionComponent } from './new-position.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    IonCustomScrollbarModule
  ],
  declarations: [NewPositionComponent],
  exports: [NewPositionComponent]
})
export class NewPositionComponentModule {}
