import './module/meteor-client';
import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { MeteorObservable } from 'meteor-rxjs';
// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Meteor;

import * as moment from 'moment';
import 'moment/min/locales';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

console.log('before meteor startup');
Meteor.startup(() => {
  console.log('after meteor startup');
  // Time of inactivity to set user as away automaticly. Default 60000
  // UserPresence.awayTime = 30000;
  // Set user as away when window loses focus. Defaults false
  // UserPresence.awayOnWindowBlur = true;
  // Start monitor for user activity
  // UserPresence.start();
  const sub = MeteorObservable.autorun().subscribe(() => {

    if (Meteor.loggingIn()) {
      return;
    }

    setTimeout(() => sub.unsubscribe());
    console.log('nav:', navigator);
    const languages = [...navigator.languages];
    let locale = languages.reverse().find(lang => lang.indexOf('-') > -1);
    if (!locale) {
      locale = navigator.language;
    }
    console.log(locale);
    moment.updateLocale(locale, {
    // moment.updateLocale('en', {
      week : {
        dow: 1,
        doy: 1
      }
    });

    platformBrowserDynamic().bootstrapModule(AppModule).then(() => {

    }).catch(err => console.log(err));
  });
});
