import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './_services/auth-guard.service';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'register-account', loadChildren: () => import('./register-account/register-account.module').then(m => m.RegisterAccountPageModule)},
  { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
  { path: 'business-directory', loadChildren: () => import('./business-directory/business-directory.module').then(m => m.BusinessDirectoryPageModule), canActivate: [AuthGuardService] },
  { path: 'chat', loadChildren: () => import('./chat/chat.module').then(m => m.ChatPageModule), canActivate: [AuthGuardService] },
  { path: 'chat-details', loadChildren: () => import('./chat-details/chat-details.module').then(m => m.ChatDetailsPageModule), canActivate: [AuthGuardService] },
  { path: 'chat-list/org', loadChildren: () => import('./chat-list/chat-list.module').then(m => m.ChatListPageModule), canActivate: [AuthGuardService] },
  { path: 'chat-list/mine', loadChildren: () => import('./chat-list/chat-list.module').then(m => m.ChatListPageModule), canActivate: [AuthGuardService] },
  { path: 'account-links-list', loadChildren: () => import('./account-links-list/account-links-list.module').then(m => m.AccountLinksListPageModule), canActivate: [AuthGuardService] },
  { path: 'event-details', loadChildren: () => import('./event-details/event-details.module').then(m => m.EventDetailsPageModule), canActivate: [AuthGuardService] },
  { path: 'event-list', loadChildren: () => import('./event-list/event-list.module').then(m => m.EventListPageModule), canActivate: [AuthGuardService] },
  { path: 'gear-orders', loadChildren: () => import('./gear-orders/gear-orders.module').then(m => m.GearOrdersPageModule), canActivate: [AuthGuardService] },
  { path: 'gear-orders-admin', loadChildren: () => import('./gear-orders-admin/gear-orders-admin.module').then(m => m.GearOrdersAdminPageModule), canActivate: [AuthGuardService] },
  { path: 'gear-kit-list', loadChildren: () => import('./gear-kit-list/gear-kit-list.module').then(m => m.GearKitListPageModule), canActivate: [AuthGuardService] },
  { path: 'gear-shop-list', loadChildren: () => import('./gear-shop-list/gear-shop-list.module').then(m => m.GearShopListPageModule), canActivate: [AuthGuardService] },
  { path: 'group-details', loadChildren: () => import('./group-details/group-details.module').then(m => m.GroupDetailsPageModule), canActivate: [AuthGuardService] },
  { path: 'group-list', loadChildren: () => import('./group-list/group-list.module').then(m => m.GroupListPageModule), canActivate: [AuthGuardService] },
  { path: 'hide-profile-fields', loadChildren: () => import('./hide-profile-fields/hide-profile-fields.module').then(m => m.HideProfileFieldsPageModule), canActivate: [AuthGuardService] },
  { path: 'match-report', loadChildren: () => import('./match-report/match-report.module').then(m => m.MatchReportPageModule), canActivate: [AuthGuardService] },
  { path: 'match-reports', loadChildren: () => import('./match-reports/match-reports.module').then(m => m.MatchReportsPageModule), canActivate: [AuthGuardService] },
  { path: 'member-details', loadChildren: () => import('./member-details/member-details.module').then(m => m.MemberDetailsPageModule), canActivate: [AuthGuardService] },
  { path: 'new-business-directory', loadChildren: () => import('./new-business-directory/new-business-directory.module').then(m => m.NewBusinessDirectoryPageModule), canActivate: [AuthGuardService] },
  { path: 'new-event', loadChildren: () => import('./new-event/new-event.module').then(m => m.NewEventPageModule), canActivate: [AuthGuardService] },
  { path: 'new-group', loadChildren: () => import('./new-group/new-group.module').then(m => m.NewGroupPageModule), canActivate: [AuthGuardService] },
  { path: 'new-match-report', loadChildren: () => import('./new-match-report/new-match-report.module').then(m => m.NewMatchReportPageModule), canActivate: [AuthGuardService] },
  { path: 'new-organisation', loadChildren: () => import('./new-organisation/new-organisation.module').then(m => m.NewOrganisationPageModule), canActivate: [AuthGuardService] },
  { path: 'new-sponsor', loadChildren: () => import('./new-sponsor/new-sponsor.module').then(m => m.NewSponsorPageModule), canActivate: [AuthGuardService] },
  { path: 'new-season', loadChildren: () => import('./new-season/new-season.module').then(m => m.NewSeasonPageModule), canActivate: [AuthGuardService] },
  { path: 'season-member-invite', loadChildren: () => import('./season-member-invite/season-member-invite.module').then(m => m.SeasonMemberInvitePageModule), canActivate: [AuthGuardService] },
  { path: 'season-member-list', loadChildren: () => import('./season-member-list/season-member-list.module').then(m => m.SeasonMemberListPageModule), canActivate: [AuthGuardService] },
  { path: 'org-anouncements', loadChildren: () => import('./org-anouncements/org-anouncements.module').then(m => m.OrgAnouncementsPageModule), canActivate: [AuthGuardService] },
  { path: 'org-details', loadChildren: () => import('./org-details/org-details.module').then(m => m.OrgDetailsPageModule), canActivate: [AuthGuardService] },
  { path: 'org-admin-reports', loadChildren: () => import('./org-admin-reports/org-admin-reports.module').then(m => m.OrgAdminReportsPageModule), canActivate: [AuthGuardService] },
  { path: 'personal-profile', loadChildren: () => import('./personal-profile/personal-profile.module').then(m => m.PersonalProfilePageModule), canActivate: [AuthGuardService] },
  { path: 'personal-profile-edit', loadChildren: () => import('./personal-profile-edit/personal-profile-edit.module').then(m => m.PersonalProfileEditPageModule), canActivate: [AuthGuardService] },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule), canActivate: [AuthGuardService] },
  { path: 'sponsors', loadChildren: () => import('./sponsors/sponsors.module').then(m => m.SponsorsPageModule), canActivate: [AuthGuardService] },
  { path: 'gear-manage', loadChildren: () => import('./gear-manage/gear-manage.module').then(m => m.GearManagePageModule), canActivate: [AuthGuardService] },
  { path: 'gear-manage-list', loadChildren: () => import('./gear-manage-list/gear-manage-list.module').then(m => m.GearManageListPageModule), canActivate: [AuthGuardService] },
  { path: 'event-attendance', loadChildren: () => import('./event-attendance/event-attendance.module').then(m => m.EventAttendancePageModule), canActivate: [AuthGuardService] },
  { path: 'event-assessments', loadChildren: () => import('./event-assessments/event-assessments.module').then(m => m.EventAssessmentsPageModule), canActivate: [AuthGuardService] },
  { path: 'player-report-selection', loadChildren: () => import('./player-report-selection/player-report-selection.module').then(m => m.PlayerReportSelectionPageModule), canActivate: [AuthGuardService] },
  { path: 'player-reports', loadChildren: () => import('./player-reports/player-reports.module').then(m => m.PlayerReportsPageModule), canActivate: [AuthGuardService] },
  { path: 'team-reports', loadChildren: () => import('./team-reports/team-reports.module').then(m => m.TeamReportsPageModule), canActivate: [AuthGuardService] },
  { path: 'event-assessment-player', loadChildren: () => import('./event-assessment-player/event-assessment-player.module').then(m => m.EventAssessmentPlayerPageModule), canActivate: [AuthGuardService] },
  { path: 'event-member-list', loadChildren: () => import('./event-member-list/event-member-list.module').then(m => m.EventMemberListPageModule), canActivate: [AuthGuardService] },
  { path: 'manage-event-types', loadChildren: () => import('./manage-event-types/manage-event-types.module').then(m => m.ManageEventTypesPageModule), canActivate: [AuthGuardService] },
  { path: 'new-event-type', loadChildren: () => import('./new-event-type/new-event-type.module').then(m => m.NewEventTypePageModule), canActivate: [AuthGuardService] },
  { path: 'my-accreditations', loadChildren: () => import('./my-accreditations/my-accreditations.module').then(m => m.MyAccreditationsPageModule), canActivate: [AuthGuardService] },
  { path: 'my-accreditation', loadChildren: () => import('./my-accreditation/my-accreditation.module').then(m => m.MyAccreditationPageModule), canActivate: [AuthGuardService] },
  { path: 'new-my-accreditation', loadChildren: () => import('./new-my-accreditation/new-my-accreditation.module').then(m => m.NewMyAccreditationPageModule), canActivate: [AuthGuardService] },
  { path: 'season-details', loadChildren: () => import('./season-details/season-details.module').then(m => m.SeasonDetailsPageModule), canActivate: [AuthGuardService] },
  { path: 'season-list', loadChildren: () => import('./season-list/season-list.module').then(m => m.SeasonListPageModule), canActivate: [AuthGuardService] },
  { path: 'registrations-list', loadChildren: () => import('./registrations-list/registrations-list.module').then(m => m.RegistrationsPageModule), canActivate: [AuthGuardService] },
  { path: 'registration-details', loadChildren: () => import('./registration-details/registration-details.module').then(m => m.RegistrationDetailsPageModule), canActivate: [AuthGuardService] },
  { path: 'new-registration', loadChildren: () => import('./new-registration/new-registration.module').then(m => m.NewRegistrationPageModule), canActivate: [AuthGuardService] },
  { path: 'trial-registration-desk', loadChildren: () => import('./trial-registration-desk/trial-registration-desk.module').then(m => m.TrialRegistrationDeskPageModule), canActivate: [AuthGuardService] },
  { path: 'trial-assessment-events-list', loadChildren: () => import('./trial-assessment-events-list/trial-assessment-events-list.module').then(m => m.TrialAssessmentEventsListPageModule), canActivate: [AuthGuardService] },
  { path: 'trial-reports', loadChildren: () => import('./trial-reports/trial-reports.module').then(m => m.TrialReportsPageModule), canActivate: [AuthGuardService] },
  { path: 'new-assessment-template', loadChildren: () => import('./new-assessment-template/new-assessment-template.module').then(m => m.NewAssessmentTemplatePageModule), canActivate: [AuthGuardService] },
  { path: 'assessment-template-list', loadChildren: () => import('./assessment-template-list/assessment-template-list.module').then(m => m.AssessmentTemplateListPageModule), canActivate: [AuthGuardService] },
  // { path: 'admin-member-checklist-list', loadChildren: () => import('./admin-member-checklist-list/admin-member-checklist-list.module').then(m => m.AdminMemberChecklistListPageModule), canActivate: [AuthGuardService] },
  { path: 'admin-member-checklist', loadChildren: () => import('./admin-member-checklist/admin-member-checklist.module').then(m => m.AdminMemberChecklistPageModule), canActivate: [AuthGuardService] },
  { path: 'report-picker', loadChildren: () => import('./report-picker/report-picker.module').then(m => m.ReportPickerPageModule), canActivate: [AuthGuardService] },
  { path: 'new-child', loadChildren: () => import('./new-child/new-child.module').then(m => m.NewChildPageModule), canActivate: [AuthGuardService] },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
