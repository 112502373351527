<ion-header>

  <ion-toolbar color="primary">
    <ion-buttons slot="start">
        <ion-button (click)="close()">
            Close
        </ion-button>
      </ion-buttons>
    <ion-title>Season Invites</ion-title>
  </ion-toolbar>

</ion-header>
<ion-content scrollbar>
  <ion-list class="ion-no-padding">
    <ion-card *ngFor="let user of invites">
      <ion-item>
        <ion-label>
          {{user.profile.firstName}} {{user.profile.lastName}} <span *ngIf="user._id !== userId"><ion-note>(child)</ion-note></span>
        </ion-label>
      </ion-item>
      <ion-item *ngFor="let invite of user.invites">
        <ion-label>
          {{invite.orgName}}
          <p>
            <ion-note>{{invite.name}}</ion-note>
          </p>
          <p>
            <ion-note>invited: {{invite.inviteDate | amDateFormat:'DD MMM YYYY'}}</ion-note>
          </p>
        </ion-label>
        <ion-button slot="end" (click)="accept(invite, user._id)">Accept</ion-button>
      </ion-item>
    </ion-card>
  </ion-list>
</ion-content>
