import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { PresentersService } from './presenters.service';
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';
import { Platform } from '@ionic/angular';

declare let Meteor: any;

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  devCount = 0;
  isDevMode = false;

  tmpLog;
  tmpWarn;
  tmpInfo;

  // browser; //the inAppBrowser pointer
  // urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

  constructor(
    public platform: Platform,
    private androidPermissions: AndroidPermissions,
    public presenters: PresentersService,
    private iab: InAppBrowser
  ) {
    if(window.localStorage.getItem('__enable_dev')) {
      this.isDevMode = true;
    }

    this.tmpLog = window.console.log;
    this.tmpWarn = window.console.warn;
    this.tmpInfo = window.console.info;

    if(!window.localStorage.getItem('debug')) {
      window.console.log = () => {};
      window.console.warn = () => {};
      window.console.info = () => {};
    }
  }

  debugMode() {
    if(!window.localStorage.getItem('debug')) {
      window.localStorage.setItem('debug', 'true');
      this.presenters.presentToast({
        message: `Debug mode actived.`,
        duration: 2000,
        position: 'bottom'
      });
      window.console.log = this.tmpLog;
      window.console.warn = this.tmpWarn;
      window.console.info = this.tmpInfo;
    } else {
      window.localStorage.removeItem('debug');
      this.presenters.presentToast({
        message: `Debug mode deactived.`,
        duration: 2000,
        position: 'bottom'
      });
      window.console.log = () => {};
      window.console.warn = () => {};
      window.console.info = () => {};
    }
  }


  devMode() {
    if(this.isDevMode) {
      this.presenters.presentAlert({
        header: 'Dev mode.',
        message: `Return to normal mode?`,
        buttons: [
          {
            text: 'Yes',
            handler: data => {
              window.localStorage.removeItem('__enable_dev');
              window.localStorage.removeItem('debug');
              console.log('Reloading App clicked');
              this.forceReload();
            }
          }, {
            text: 'No',
            handler: data => {
              console.log('Reloading App clicked');
            }
          }
        ]
      });
    } else {
      if(this.devCount < 10) {
        this.devCount++;

        if(this.devCount > 5) {
          this.presenters.presentToast({
              message: `${10 - this.devCount} until dev mode.`,
              duration: 500,
              position: 'top'
          });
        }

        if(this.devCount === 10) {
          window.localStorage.setItem('__enable_dev','dev');
          window.localStorage.setItem('debug', 'true');
          this.presenters.presentAlert({
                header: 'Dev mode.',
                message: `Dev mode active, please restart the app`,
                buttons: [
                  {
                    text: 'Ok',
                    handler: data => {
                      console.log('Reloading App clicked');
                      this.forceReload();
                    }
                  }
                ]
          });
        }

        setTimeout(() => this.devCount = 0, 10000);
      }
    }
  }

  b64toBlob(dataURI) {

    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  randomString(len) {
    const r = Math.random().toString(36).substring(len+1);
    console.log('random', r);
    return r;
  }

  visitLink(link, target = '_blank', opts = 'location=yes') {
    console.log(`Go to link ${encodeURI(link)},
    target: ${target},
    opts: ${opts}
    `);

    // if(navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/)) {
    //   let modal = this.modalCtrl.create(WebframePage, {url:link});
    //   modal.present();
    // } else {
      // window.open(encodeURI(link), '_blank');
      if(this.platform.is('android')) {
        if(link.toLowerCase().search(".pdf") != -1) {
          link = 'https://docs.google.com/viewer?url='+link;
        }
      }
      let ref = this.iab.create(encodeURI(link), target, opts);
      // console.log(ref);
      // ref.on('loadstart').subscribe(event => {
      //   document.getElementById('#status-message').innerHTML ="loading please wait ...";
      // });

      ref.on('loaderror').subscribe(event => {
          console.log('Sorry we cannot open that page. Message from the server is : ', event.message);
          this.presenters.presentAlert({
            header: 'Can not open.',
            message: 'Sorry we cannot open that page. Message from the server is :' + event.message,
            buttons: [
              {
                text: 'Ok',
                handler: data => {}
              }
            ]
          });
          ref.close();
          ref = undefined;
      });
      ref.on('exit').subscribe(event => { 
        console.log('IAB exit:', event);
        ref.close();
        ref = undefined;
      });

  }

  forceReload() {
    // eslint-disable-next-line import/no-deprecated
    window.location.reload();
  }

  webShare(title, text, url) {
    if (window.navigator && window.navigator['share']) {
      window.navigator['share']({
          title,
          text,
          url,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }

  async checkAndroidPermissions(permArr) {
    const reqArr = [];
    await Promise.all(permArr.map(async (perm) => {
      const fullPerm = this.androidPermissions.PERMISSION[perm];
      const res = await this.androidPermissions.checkPermission(fullPerm);
      console.log(perm, res);
      if(!res || !res.hasPermission) {
        reqArr.push(fullPerm);
      }
    }));

    console.log('outside ', reqArr);
    // Promise.all(promiseArr).then(() => {
      console.log('inside ', reqArr);
      if(reqArr.length === 0) {
        // cb();
      } else {
        try {
          const reqPerms = await this.androidPermissions.requestPermissions(reqArr);
          console.log(reqPerms);
          console.log(`res req perm ${reqArr} : `, reqPerms);
          if( !reqPerms || !reqPerms.hasPermission || reqPerms.hasPermission === false || reqPerms.hasPermission === 'false' ) {
            this.errorAndroidPermission();
          } else if(reqPerms.hasPermission === true || reqPerms.hasPermission === 'true') {
            // cb();
          }
        } catch (error) {
            console.log(`err req perm ${reqArr} : `, error);
            this.errorAndroidPermission(error.message);
        }
        // }, error => {
        //   console.log(`err req perm ${reqArr} : `, error);
        //   this.errorAndroidPermission(error.message);
        // })
      }
    // })
  }

  errorAndroidPermission(errMsg = '') {
    this.presenters.presentAlert({
      header: 'No Permission',
      message: `You will need to enable permission for the ClubEzy app. ${errMsg}`,
      buttons: [
        'Ok'
      ]
    });
  }

  meteorStatus() {
    return Meteor.status().status;
  }

  urlify(text) {
    const linkArr = linkify.find(text);
    const linkHtml = linkifyHtml(text, {
      ignoreTags: [
        'script',
        'style'
      ],
      defaultProtocol: 'https',
      target: '_blank'
    });
    // console.log('linki finder');
    // console.log(linkArr);
    // console.log(linkHtml);
    // return text.match(this.urlRegex, (url) => url);
    return {links:linkArr.map((lnk) => lnk.href), html:linkHtml};
  }
}
