import { AccreditationService } from './_services/accreditations.service';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { ComponentsModule } from './_components';
import { PipesModule } from './_pipes/pipes.module';
import { ActivitiesService } from './_services/activities.service';
import { ConfigService } from './_services/config.service';
import { ProfileService } from './_services/profile.service';
import { UtilService } from './_services/util.service';
// import { TermsComponentModule, BdListsComponentModule, AddressFormatterComponentModule, MenuPopoverComponentModule } from './_components';
import { AddressFormatterComponentModule, BdListsComponentModule, CustomRepeatComponentModule, TermsComponentModule, MenuPopoverComponentModule,
  EventIntervalComponentModule, FilterEventListComponentModule, TagManageComponentModule, EventSelectListComponentModule, NewCriteriaComponentModule,
  EditHistoryComponentModule, NewAccreditationComponentModule, ImageModalComponentModule, FilterTrialReportComponentModule, AddPaymentComponentModule,
  SeasonInvitesComponentModule, FileUploadComponentModule, LastActiveAtComponentModule, SponsorSelectComponentModule, InviteMembersComponentModule,
  ToggleNotificationComponentModule} from './_components';
import { TermsComponent } from './_components/terms/terms.component';
import { ToggleNotificationComponent } from './_components/toggle-notification/toggle-notification.component';
import { BdListsComponent } from './_components/bd-lists/bd-lists.component';
import { AddressFormatterComponent } from './_components/address-formatter/address-formatter.component';
import { MenuPopoverComponent } from './_components/menu-popover/menu-popover.component';
import { EventIntervalComponent } from './_components/event-interval/event-interval.component';
import { FilterEventListComponent } from './_components/filter-event-list/filter-event-list.component';
import { TagManageComponent } from './_components/tag-manage/tag-manage.component';
import { InviteMembersComponent } from './_components/invite-members/invite-members.component';
import { CustomRepeatComponent } from './_components/custom-repeat/custom-repeat.component';
import { EventSelectListComponent } from './_components/event-select-list/event-select-list.component';
import { NewCriteriaComponent } from './_components/new-criteria/new-criteria.component';
import { NewAccreditationComponent } from './_components/new-accreditation/new-accreditation.component';
import { EditHistoryComponent } from './_components/edit-history/edit-history.component';
import { SeasonInvitesComponent } from './_components/season-invites/season-invites.component';
import { FileUploadComponent } from './_components/file-upload/file-upload.component';
import { LastActiveAtComponent} from './_components/last-active-at/last-active-at.component';
import { SponsorSelectComponent } from './_components/sponsor-select/sponsor-select.component';
import { IonicGestureConfig } from './_directives/ionic-gesture-config.directive';
import { DirectivesModule } from './_directives/directives.module';
import { IntercomModule } from 'ng-intercom';

// export declare const Meteor;
import * as Sentry from '@sentry/browser';

import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

import { ImageModalComponent } from './_components/image-modal/image-modal.component';
import { MemberPermissionsComponent } from './_components/member-permissions/member-permissions.component';
import { MemberPermissionsComponentModule } from './_components/member-permissions/member-permissions.module';
import { NewPositionComponentModule } from './_components/new-position/new-position.module';
import { NewPositionComponent } from './_components/new-position/new-position.component';
import { FilterTrialReportComponent } from './_components/filter-trial-report/filter-trial-report.component';
import { AddPaymentComponent } from './_components/add-payment/add-payment.component';

// Sentry.init({
//   dsn: 'https://5cf3ec9c6f7148c692d36e05862dd1d3@sentry.io/289176'
// });
// Raven.config('https://5cf3ec9c6f7148c692d36e05862dd1d3@sentry.io/289176').install();
// console.log(Meteor);
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({ eventId });
    // console.error(error.originalError || error);
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [
    TermsComponent,
    BdListsComponent,
    AddressFormatterComponent,
    CustomRepeatComponent,
    MenuPopoverComponent,
    EventIntervalComponent,
    FilterEventListComponent,
    TagManageComponent,
    ToggleNotificationComponent,
    InviteMembersComponent,
    EventSelectListComponent,
    NewCriteriaComponent,
    NewAccreditationComponent,
    SponsorSelectComponent,
    EditHistoryComponent,
    ImageModalComponent,
    MemberPermissionsComponent,
    NewPositionComponent,
    FilterTrialReportComponent,
    AddPaymentComponent,
    SeasonInvitesComponent,
    FileUploadComponent,
    LastActiveAtComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('OneSignalSDKWorker.js', { enabled: environment.platform === 'pwa' }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    PipesModule,
    DirectivesModule,
    TermsComponentModule,
    BdListsComponentModule,
    AddressFormatterComponentModule,
    CustomRepeatComponentModule,
    MenuPopoverComponentModule,
    EventIntervalComponentModule,
    FilterEventListComponentModule,
    TagManageComponentModule,
    InviteMembersComponentModule,
    HttpClientModule,
    EventSelectListComponentModule,
    NewCriteriaComponentModule,
    NewAccreditationComponentModule,
    EditHistoryComponentModule,
    ImageModalComponentModule,
    MemberPermissionsComponentModule,
    NewPositionComponentModule,
    FilterTrialReportComponentModule,
    ToggleNotificationComponentModule,
    SponsorSelectComponentModule,
    AddPaymentComponentModule,
    SeasonInvitesComponentModule,
    FileUploadComponentModule,
    LastActiveAtComponentModule,
    IntercomModule.forRoot({
      appId: environment.intercom.appId,
      updateOnRouterChange: true
    }),
    IonCustomScrollbarModule,
    HammerModule
  ],
  providers: [
    AndroidPermissions,
    AppVersion,
    BackgroundMode,
    Chooser,
    Camera,
    Deeplinks,
    OneSignal,
    File,
    InAppBrowser,
    FileTransfer,
    Keyboard,
    StatusBar,
    SplashScreen,
    WebView,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ActivitiesService,
    ProfileService,
    ConfigService,
    UtilService,
    AccreditationService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: IonicGestureConfig
    },
    {provide: ErrorHandler, useClass: environment.production ? SentryErrorHandler : ErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
