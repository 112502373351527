/* eslint-disable */ 
import { MongoObservable } from 'meteor-rxjs';
// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Meteor: any;

import { Message, Chat, Group, Event, Announcements,
  Organisation, Gear, Assessment, MatchReports,
  NewRegistration, Seasons, AssessmentTemplate, Orders} from '../models/clubezy-models';

// declare var Meteor: any;
declare var Roles: any;

function loggedIn() {
  return !!Meteor.user();
}
function isAdmin(user) {
  console.log(user);
  const org = Meteor.user().profile.lastOrg;
  console.log(org);
  // console.log('user Id Admin CHeck: ', user)
  return Roles.userIsInRole(user, ['super_user', 'admin'], org);
}
// function isFalse() {
//   return false;
// }
// Part of Meteor
export const CEUsers = MongoObservable.fromExisting(Meteor.users);

export const CEChats = new MongoObservable.Collection<Chat>('chats');
export const CEMessages = new MongoObservable.Collection<Message>('messages');

export const CEAnnouncements = new MongoObservable.Collection<Announcements>('announcements');
export const CEOrganisations = new MongoObservable.Collection<Organisation>('organisations');
export const CENewRegistrations = new MongoObservable.Collection<NewRegistration>('new_registrations');

export const CEGroups = new MongoObservable.Collection<Group>('groups');
export const CEEvents = new MongoObservable.Collection<Event>('events');

export const CEMatchReports = new MongoObservable.Collection<MatchReports>('match_reports');

export const CEAssessments = new MongoObservable.Collection<Assessment>('assessments');

export const CEGear = new MongoObservable.Collection<Gear>('gear');
export const CEOrders = new MongoObservable.Collection<Orders>('orders');

export const CESeasons = new MongoObservable.Collection<Seasons>('seasons');

CEUsers.allow({
  update: loggedIn
});

Meteor.users.allow({
  update: function itsTrue() {return true; }
});

CEGroups.allow({
  insert: loggedIn,
  update: loggedIn,
  remove: isAdmin
});

CEMessages.allow({
  insert: loggedIn,
  update: loggedIn,
  remove: isAdmin
});

CEEvents.allow({
  insert: loggedIn,
  update: loggedIn,
  remove: isAdmin
});

CEChats.allow({
  insert: loggedIn,
  update: loggedIn,
  remove: isAdmin
});

CEAnnouncements.allow({
  insert: loggedIn,
  update: loggedIn,
  remove: isAdmin
});

CEOrganisations.allow({
  insert: loggedIn,
  update: loggedIn,
  remove: isAdmin
});

CEGear.allow({
  insert: loggedIn,
  update: loggedIn,
  remove: isAdmin
});

CEAssessments.allow({
  insert: loggedIn,
  update: loggedIn,
  remove: isAdmin
});

CEMatchReports.allow({
  insert: loggedIn,
  update: isAdmin,
  remove: isAdmin
});

CENewRegistrations.allow({
  insert: loggedIn,
  update: isAdmin,
  remove: isAdmin
});

CESeasons.allow({
  insert: loggedIn,
  update: isAdmin,
  remove: isAdmin
});

CEOrders.allow({
  insert: loggedIn,
  update: isAdmin,
  remove: isAdmin
});
