import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventFilterPipe } from './event-filter.pipe';
import { TrialReportFilterPipe } from './trial-report-filter.pipe';
import { EventDateRangePipe } from './event-date-range.pipe';
import { FormatLocalPipe } from './format-local.pipe';
import { DateSortPipe } from './date-sort.pipe';
import { SearchNamesPipe } from './search-names.pipe';
import { IsInGroupPipe } from './is-in-group.pipe';
import { NameSearchPipe } from './name-search.pipe';
import { ArrayFilterPipe } from './array-filter.pipe';
import { GearSizeFilterPipe } from './gear-size-filter.pipe';
import { ChatSortPipe } from './chat-sort.pipe';

@NgModule({
  declarations: [
    EventFilterPipe,
    EventDateRangePipe,
    FormatLocalPipe,
    DateSortPipe,
    SearchNamesPipe,
    IsInGroupPipe,
    NameSearchPipe,
    ArrayFilterPipe,
    GearSizeFilterPipe,
    ChatSortPipe,
    TrialReportFilterPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EventFilterPipe,
    EventDateRangePipe,
    FormatLocalPipe,
    DateSortPipe,
    SearchNamesPipe,
    IsInGroupPipe,
    NameSearchPipe,
    ArrayFilterPipe,
    GearSizeFilterPipe,
    ChatSortPipe,
    TrialReportFilterPipe
  ],
})
export class PipesModule { }
