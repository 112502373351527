<ion-app>
    <ion-split-pane contentId="menu-content" (ionSplitPaneVisible)="splitChanged($event)">
        <ion-menu side="start" contentId="menu-content" (ionDidOpen)="menuOpened()" (ionDidClose)="menuClosed()">
          <ion-header class="side-menu-image" [ngStyle]="user && {'background-image':'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('+user.profile.images?.background+')'}">
            <ion-item lines="none">
              <ion-avatar slot="start">
                <img *ngIf="user?.profile?.images" [src]="user.profile.images.thumb">
              </ion-avatar>
              <ion-label *ngIf="user" class="ion-text-wrap">
              <h2 class="menu-text">{{user.profile.firstName}} {{user.profile.lastName}}</h2>
              <h3 *ngIf="selectedOrganisation" class="role-text">{{selectedOrganisation.name}}</h3>
              <h3 *ngIf="selectedSeason" class="role-text">{{selectedSeason.name}}</h3>
              <!-- <h3 *ngIf="user.isAdmin" class="role-text">Administrator</h3>
              <h3 *ngIf="user.isOwner" class="role-text">Owner</h3>
              <h3 *ngIf="user.isSuper" class="role-text">Super User</h3> -->
              </ion-label>
              <ion-button fill="clear" color="light" slot="end" shape="round" (click)='toggleOrgSelect()'>
                <ion-icon slot="icon-only" [name]="(showOrgSelect?'caret-up-circle':'caret-down-circle')"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-header>
          <ion-content scrollbar class="scroll-auto">
          <!-- <div class="org-select" [class.org-select-expanded]="showOrgSelect" *ngIf="showOrgSelect"> -->
            <!-- <div class="org-select-wrap" [class.org-select-wrap-expanded]="showOrgSelect"> -->
              <div class="org-select-wrap" [@openClose]="showOrgSelect ? 'open' : 'closed'">
              <ion-refresher slot="fixed" (ionRefresh)="doSeasonRefresh($event)" *ngIf="showOrgSelect">
                <ion-refresher-content
                  pullingIcon="caret-down"
                  pullingText="Pull to refresh"
                  refreshingSpinner="circles"
                  refreshingText="Refreshing...">
                </ion-refresher-content>
              </ion-refresher>
            <!-- <ion-list class="org-select ion-no-padding" [class.org-select-expanded]="showOrgSelect"> -->
              <!-- <ion-list class="org-select ion-no-padding" [@openClose]="showOrgSelect ? 'open' : 'closed'"> -->
              <ion-list class="org-select ion-no-padding">
              <ion-item *ngIf="pendingSeasonInvites.length > 0" (click)="showOrgInvites()">
                <ion-label class="ion-text-wrap">Pending Invites</ion-label>
                <ion-badge slot="end" color="danger">{{pendingSeasonInvites.length}}</ion-badge>
              </ion-item>
              <ion-item class="ion-text-center ion-text-wrap" *ngIf="seasonList?.length===0">
                <ion-label class="ion-text-wrap"><ion-text><p>Your are not in any Seasons.</p></ion-text></ion-label>
              </ion-item>
              <ion-item class="side-menu-item" *ngFor="let season of seasonList" (click)="changeSeason(season)" detail="false" [ngClass]="{'selected': season?._id && selectedSeason?._id && (season._id===selectedSeason._id) }">
                <!-- <ion-item class="side-menu-item" *ngFor="let season of activities.seasonList | async" (click)="changeSeason(season)" detail="false"> -->
                  <ion-label *ngIf="season?._id" class="ion-text-wrap">
                    <h2>{{season.organisationObj.name}}</h2>
                    <h3> {{season.name}}</h3>
                  </ion-label>
                  <ion-note slot="end" *ngIf="season?._id && season?.active">
                    active
                  </ion-note>
              </ion-item>
              <ion-button expand="block" (click)="profile.loginAsOther()" *ngIf="profile.isSuper">Masquerade</ion-button>
              <ion-button expand="block" (click)="profile.loginAsOther()" *ngIf="profile.isMasqued">Return to Account</ion-button>
              <ion-button expand="block" (click)="gotoCreateOrg()" *ngIf="profile.isSuper">Create Organisation</ion-button>

            </ion-list>
          </div>
          <ion-grid class="ion-no-padding">
            <ion-row class="height100">
              <ion-col class="side-menu-wheel" size="4">
                <ion-list class="ion-no-padding" lines="none">
                <!-- <ion-slides [direction]="'vertical'" [slidesPerView]="6"> -->
                <!-- <ion-slide> -->
                    <ion-item class="ion-text-center ion-text-wrap ion-no-padding" [hidden]="!selectedOrganisation.name" lines="none" (click)="selectedMenu='myclub'" [ngClass]="{'selected': selectedMenu==='myclub' }" detail="false">
                        <ion-label>
                      <ion-avatar class="side-img">
                        <img [src]="selectedOrganisation?.image?.thumb?selectedOrganisation.image.thumb:'assets/img/jersey.svg'">
                      </ion-avatar>
                      <ion-badge class="overlay-badge" color="danger" *ngIf="pagesMyClub[1] && pagesMyClub[1].badge > 0">{{pagesMyClub[1].badge}}</ion-badge>
                      <h3 *ngIf="selectedOrganisation">{{selectedOrganisation.shortName ? selectedOrganisation.shortName : selectedOrganisation.name}}</h3>
                    </ion-label>
                    </ion-item>
                  <!-- </ion-slide> -->
                  <!-- <ion-slide> -->
                  <ion-item class="ion-text-center ion-text-wrap ion-no-padding" *ngIf="user" lines="none" (click)="selectedMenu='home'" [ngClass]="{'selected': selectedMenu==='home' }" detail="false">
                      <ion-label>
                    <ion-avatar class="side-img">
                      <img [src]="'assets/img/user.svg'">
                    </ion-avatar>
                    <h3>My Profile</h3>
                  </ion-label>
                  </ion-item>
                  <!-- </ion-slide> -->
                  <!-- <ion-slide> -->
                  <ion-item class="ion-text-center ion-text-wrap ion-no-padding" *ngIf="user" lines="none" (click)="selectedMenu='calendar'" [ngClass]="{'selected': selectedMenu==='calendar' }" detail="false">
                      <ion-label>
                        <ion-avatar class="side-img">
                      <img [src]="'assets/img/calendar.svg'">
                    </ion-avatar>
                    <h3>Activities</h3>
                  </ion-label>
                  </ion-item>
                  <!-- </ion-slide> -->
                  <!-- <ion-slide> -->
                  <ion-item class="ion-text-center ion-text-wrap ion-no-padding badge-wrap" [hidden]="pagesMessages.length === 0" lines="none" (click)="selectedMenu='messages'"  [ngClass]="{'selected': selectedMenu==='messages' }" detail="false">
                      <ion-label>
                    <ion-avatar class="side-img">
                      <img [src]="'assets/img/chat.svg'">
                    </ion-avatar>
                      <ion-badge class="overlay-badge" color="danger" *ngIf="(pagesMessages.length === 2 && (pagesMessages[0].badge + pagesMessages[1].badge > 0)) || (pagesMessages.length === 1 && pagesMessages[0].badge > 0)">{{pagesMessages.length === 2?pagesMessages[0].badge + pagesMessages[1].badge: pagesMessages[0].badge}}</ion-badge>
                      <h3>Messages</h3>
                    </ion-label>
                  </ion-item>
                  <!-- </ion-slide> -->
                  <!-- <ion-slide> -->
                  <!--<ion-button ion-item lines="none" (click)="selectedMenu='wallet'" [ngClass]="{'selected': selectedMenu=='wallet' }" detail="false">
                    <ion-avatar>
                      <img [src]="'assets/img/icn_wallet.png'">
                    </ion-avatar>
                    <h3>Wallet</h3>
                  </ion-button>-->
                  <ion-item class="ion-text-center ion-text-wrap ion-no-padding" lines="none" (click)="selectedMenu='trials'" [ngClass]="{'selected': selectedMenu==='trials' }" detail="false" [hidden]="!user || !user._id || !selectedSeason || !selectedSeason.trials || (!user.isAdmin && !user.isCoach && !user.isOwner && !user.isSuper)">
                    <ion-label>
                      <ion-avatar class="side-img">
                          <img [src]="'assets/img/clipboard.svg'">
                      </ion-avatar>
                      <h3>Trials</h3>
                    </ion-label>
                  </ion-item>
                  <ion-item class="ion-text-center ion-text-wrap ion-no-padding" [hidden]="!selectedOrganisation.name" lines="none" (click)="selectedMenu='gear'" [ngClass]="{'selected': selectedMenu==='gear' }" detail="false">
                    <ion-label>
                      <ion-avatar class="side-img">
                          <img [src]="'assets/img/football-jersey.svg'">
                      </ion-avatar>
                      <h3>Gear</h3>
                    </ion-label>
                  </ion-item>
                  <ion-item class="ion-text-center ion-text-wrap ion-no-padding" [hidden]="!selectedOrganisation.name" lines="none" (click)="selectedMenu='sponsors'" [ngClass]="{'selected': selectedMenu==='sponsors' }" detail="false">
                    <ion-label>
                      <ion-avatar class="side-img">
                          <img [src]="'assets/img/agenda.svg'">
                      </ion-avatar>
                      <h3>Community</h3>
                    </ion-label>
                  </ion-item>
                  <!-- </ion-slide> -->
                  <!-- <ion-slide> -->
                  <!--<ion-button ion-item lines="none" (click)="selectedMenu='ezy'" [ngClass]="{'selected': selectedMenu=='ezy' }" detail="false">
                    <ion-avatar>
                      <img [src]="'assets/img/icn_ezy.png'">
                    </ion-avatar>
                    <h3>Ezy</h3>
                  </ion-button>-->
                  <!-- </ion-slide> -->
                <!-- </ion-slides> -->
                </ion-list>
              </ion-col>
              <ion-col class="side-menu-lists" size="8">

                <ion-menu-toggle [autoHide]="false">
                <ion-list class="ion-no-padding" lines="none" *ngIf="selectedOrganisation.name && selectedMenu==='myclub'">
                  <div *ngFor="let p of pagesMyClub">
                    <ion-item menuClose="start" detail="false" class="side-menu-item" [routerLink]="[p.component, p.routeData]" [routerLinkActive]="'selected'"  routerDirection="root" [disabled]="p.disabled">
                        <ion-label>{{p.title}}</ion-label>
                      <ion-badge slot="end" color="danger" *ngIf="p.badge > 0">{{p.badge}}</ion-badge>
                    </ion-item>
                  </div>
                  </ion-list>
                <ion-list class="ion-no-padding" lines="none" *ngIf="selectedMenu==='home'">
                  <div *ngFor="let p of pagesHome">
                  <ion-item menuClose="start" detail="false" class="side-menu-item" [routerLink]="[p.component, p.routeData]" [routerLinkActive]="'selected'" routerDirection="root" [disabled]="p.disabled">
                      <ion-label> {{p.title}}</ion-label>
                  </ion-item>
                  </div>
                </ion-list>
                <ion-list class="ion-no-padding" lines="none" *ngIf="selectedMenu==='calendar'">
                  <div *ngFor="let p of pagesCalendar">
                  <ion-item menuClose="start" detail="false" class="side-menu-item" [routerLink]="[p.component, p.routeData]" [routerLinkActive]="'selected'" routerDirection="root" [disabled]="p.disabled">
                      <ion-label> {{p.title}}</ion-label>
                  </ion-item>
                  </div>
                </ion-list>
                <ion-list class="ion-no-padding" lines="none" *ngIf="selectedMenu==='messages'">
                  <div *ngFor="let p of pagesMessages">
                  <ion-item menuClose="start" detail="false" class="side-menu-item" [routerLink]="[p.component, p.routeData]" [routerLinkActive]="'selected'" routerDirection="root" [disabled]="p.disabled">
                      <ion-label>{{p.title}}</ion-label>
                    <ion-badge slot="end" color="danger" *ngIf="p.badge > 0">{{p.badge}}</ion-badge>
                  </ion-item>
                  </div>
                </ion-list>
                <ion-list class="ion-no-padding" lines="none" *ngIf="selectedOrganisation.name && selectedMenu==='trials' && selectedSeason.trials">
                  <div *ngFor="let p of pagesTrials">
                    <ion-item menuClose="start" detail="false" class="side-menu-item" [routerLink]="[p.component, p.routeData]" [routerLinkActive]="'selected'" routerDirection="root" [disabled]="p.disabled">
                        <ion-label>{{p.title}}</ion-label>
                    </ion-item>
                  </div>
                  </ion-list>
                  <ion-list class="ion-no-padding" lines="none" *ngIf="selectedOrganisation.name && selectedMenu==='gear'">
                    <div *ngFor="let p of pagesGear">
                   <ion-item menuClose="start" detail="false" class="side-menu-item" [routerLink]="[p.component, p.routeData]" [routerLinkActive]="'selected'" routerDirection="root" [disabled]="p.disabled">
                       <ion-label>{{p.title}}</ion-label>
                   </ion-item>
                    </div>
                 </ion-list>
                 <ion-list class="ion-no-padding" lines="none" *ngIf="selectedOrganisation.name && selectedMenu==='sponsors'">
                   <div *ngFor="let p of pagesSponsors">
                  <ion-item menuClose="start" detail="false" class="side-menu-item" [routerLink]="[p.component, p.routeData]" [routerLinkActive]="'selected'" routerDirection="root" [disabled]="p.disabled">
                      <ion-label>{{p.title}}</ion-label>
                  </ion-item>
                   </div>
                </ion-list>
                </ion-menu-toggle>
              </ion-col>
            </ion-row>
          </ion-grid>

          </ion-content>
          <ion-footer class="ion-no-border">

              <ion-button menuClose="start" class="ion-margin-top" expand="block" (click)="signOut()" id="logout-button">Log Out</ion-button>
              <ion-note class="version-text" (click)="util.devMode()" (press)="util.debugMode()">
                <p>{{config.version}}.{{config.build}} {{config.date | date:'d MMM y'}}</p>
                <p *ngIf="util.isDevMode">[DEVELOPMENT]</p>
                <p>{{util.meteorStatus()}}</p>
              </ion-note>
            </ion-footer>
        </ion-menu>

        <ion-router-outlet id="menu-content"></ion-router-outlet>
        </ion-split-pane>


</ion-app>
