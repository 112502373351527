import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { takeWhile, debounceTime, map } from 'rxjs/operators';
import { CEUsers } from '../../module/collections/clubezy-collections';
import { MeteorObservable } from 'meteor-rxjs';

import * as Sentry from '@sentry/browser';
import { PresentersService } from './presenters.service';
// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Meteor: any;
// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Roles: any;
/*
  Generated class for the Profile provider.

  See this.https:// angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  reloging: Observable<any>;
  _reloging: BehaviorSubject<any> = new BehaviorSubject(false);

  user: Observable<any>;
  _user: BehaviorSubject<any> = new BehaviorSubject(null);
  localUser: any = {};
  // children: Observable<any>;
  // _children: BehaviorSubject<any> = new BehaviorSubject({});
  isGroupAdmin = [];
  isAdmin = false;
  isCoach = false;
  isOwner = false;
  isSuper = false;
  isMasqued = false;

  children = {};
  childrenArr = [];

  childrenInCurrentOrg = [];

  childrenSub;

  parents = {};
  parentsArr = [];
  parentsSub;

  authObj: any = {};

  loggingOut = false;

  constructor(
    public presenters: PresentersService
    // public ref: ChangeDetectorRef
    ) {
    this.userSubscription();
    this.user = this._user.asObservable(); // .takeWhile(function (x) { return x < 3; });
    this.reloging = this._reloging.asObservable();
    // this.children = this._children.asObservable();
    this.init();
  }

  init() {
    // let user;
    MeteorObservable.autorun().subscribe(() => {
      const user = Meteor.user();

      // this.userSubscription();

      console.log('profile user', user);
      if (user) {
        user.profile.organisation = user.profile.organisations[user.profile.lastOrg];

        if (user.profile.lastOrg) {
          user.isAdmin = this.isAdmin = Roles.userIsInRole(user._id, ['admin', 'admin_1', 'admin_2', 'admin_3'],
          user.profile.lastOrg);
          user.isCoach = this.isCoach = Roles.userIsInRole(user._id, ['coach', 'coach_1', 'coach_2'],
          user.profile.lastOrg);
          user.isOwner = this.isOwner = Roles.userIsInRole(user._id, ['admin_3'], user.profile.lastOrg);
        }
        user.isSuper = this.isSuper = Roles.userIsInRole(user._id, ['super_user']);
        // console.log(Roles);

        if (user.profile.childAccounts.length > 0) {
          // console.log('childAccounts: ', user.profile.childAccounts);
          if (this.childrenSub) {
            this.childrenSub.unsubscribe();
          }

          this.childrenSub = CEUsers.find({
            _id: {
              $in: user.profile.childAccounts
            }
          }).pipe(
            map((x) => x),
            debounceTime(500),
            takeWhile((x: any) => {
              if (x.length > 0) {
                return x[0].profile;
              } else {
                return true;
              }
            })
          )
          .subscribe((sub: any) => {
            const childrenInOrg = [];
            this.children = {};
            this.childrenArr = sub.map((child: any) => {
              child.parents = CEUsers.find({
                _id: {
                  $in: child.profile.parentAccount
                }
              }, {
                fields: {
                  'profile.firstName': 1,
                  'profile.lastName': 1,
                  'profile.images.thumb': 1
                }
              }).fetch();
              this.children[child._id] = child;
              if (child.profile.organisations.hasOwnProperty(user.profile.lastOrg)) {
                childrenInOrg.push(child);
              }
              return child;
            });
            this.childrenInCurrentOrg = [...childrenInOrg];
          });
        } else {
          this.children = {};
          this.childrenArr = [];
        }

        if (user.profile.parentAccount.length > 0) {
          // console.log('parentAccount: ', user.profile.parentAccount);
          if (this.parentsSub) {
            this.parentsSub.unsubscribe();
          }

          this.parentsSub = CEUsers.find({
            _id: {
              $in: user.profile.parentAccount
            }
          }).pipe(
            map((x) => x),
            debounceTime(500),
            takeWhile((x: any) => {
            if (x.length > 0) {
              return x[0].profile;
            } else {
              return true;
              }
            })
          )
          .subscribe((sub: any) => {
            this.parents = {};
            this.parentsArr = sub;
            sub.map((parent: any) => {
              this.parents[parent._id] = parent;
            });
          });
        } else {
          this.parents = {};
          this.parentsArr = [];
        }
        this._user.next(user);
        this.localUser = user;
      //   Raven.setUserContext({
      //     email: user.emails[0].address,
      //     // username: user.username,
      //     id: user._id
      //   });
      if (this.isMasqued === false) {
        Sentry.configureScope((scope) => {
          scope.setUser({
            email: user.emails ? user.emails[0].address : '',
            // username: user.username,
            id: user._id
          });
        });
      }

      } else {
        console.log('No user, possibly logged out elsewhere');
        this._user.next(user);
        this.localUser = user;
      }
    });
  }

  userSubscription() {
    this.user = this._user.asObservable().pipe(
      takeWhile((x) => x != null)
    );
  }

  getMyGroupsImAdmin(org, season) {
    console.log('getMah Grups1', org, season);
    MeteorObservable.call('group.getMyGroups', org, season).subscribe((groups: any) => {
      console.log('getMah Grups2', groups);
      this.isGroupAdmin = groups.filter((group) => group.members.some((member) => member.memberId === this.localUser._id && member.permission === 'admin')).map((group) => group._id);
      console.log('im admin of:', this.isGroupAdmin);
    });
  }


  loginAsOther() {
    if (this.isMasqued !== false && typeof this.isMasqued === 'string') {
      this.presenters.presentLoader({
        message: 'Returning, Please wait...'
      });

      MeteorObservable.call('admin.logmein', this.isMasqued, true).subscribe((done: any) => {
        console.log('d: ', done);
        this.presenters.dismissLoader();

        this.presenters.presentAlert({
          header: 'Returned...',
          message: done.msg,
          buttons: ['OK']
        });
        this.isMasqued = false;
        Meteor['connection'].setUserId(done._id);
        this._reloging.next(true);
      }, (err) => {
        console.log('e: ', err);
        this.presenters.dismissLoader();
        this.presenters.presentAlert({
          header: 'Failed to Return',
          message: 'Seemed to be an error, please restart. ' + err,
          buttons: ['OK']
        });
      });
    } else {

      this.presenters.presentAlert({
        header: 'Login as User',
        message: 'Enter the email of the target user.',
        inputs: [
          {
            name: 'email',
            placeholder: 'Email'
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            handler: data => {
              console.log('Cancel clicked');
            }
          },
          {
            text: 'Change User',
            handler: data => {
              console.log('Saved clicked');
              console.log(data);
              this.presenters.presentLoader({
                message: 'Please wait...'
              });
              this.isMasqued = this.localUser.emails[0].address;
              console.log('masqued: ', this.isMasqued);
              MeteorObservable.call('admin.logmein', data.email).subscribe((done: any) => {
                console.log('d: ', done);
                this.presenters.dismissLoader();
                this.presenters.presentAlert({
                  header: 'Logging in as User...',
                  message: done.msg,
                  buttons: ['OK']
                });
                // this.isMasqued = Meteor.user().emails[0].address;
                Meteor['connection'].setUserId(done._id);
                this._reloging.next(true);
              }, (err) => {
                console.log('e: ', err);
                this.isMasqued = false;
                this.presenters.dismissLoader();
                this.presenters.presentAlert({
                  header: 'Failed to login as User',
                  message: err,
                  buttons: ['OK']
                });
              });
            }
          }
        ]
      });
    }
  }

}
