import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { EditHistoryComponent } from './edit-history.component';
import { PipesModule } from '../../_pipes/pipes.module';
import { MomentModule } from 'ngx-moment';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    MomentModule,
    IonCustomScrollbarModule
  ],
  declarations: [EditHistoryComponent],
  exports: [EditHistoryComponent]
})
export class EditHistoryComponentModule {}
