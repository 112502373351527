import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderBannerComponent } from './header-banner.component';
import { PipesModule } from '../../_pipes/pipes.module';
import { MomentModule } from 'ngx-moment';
import { LastActiveAtComponentModule } from '../last-active-at/last-active-at.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    MomentModule,
    LastActiveAtComponentModule
  ],
  declarations: [HeaderBannerComponent],
  exports: [HeaderBannerComponent]
})
export class HeaderBannerComponentModule {}
