import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MomentModule } from 'ngx-moment';
import { PipesModule } from '../../_pipes/pipes.module';
import { InviteMembersComponent } from './invite-members.component';
import { OrderModule } from 'ngx-order-pipe';

const routes: Routes = [
  {
    path: '',
    component: InviteMembersComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    MomentModule,
    PipesModule,
    OrderModule,
    IonCustomScrollbarModule
  ],
  declarations: [InviteMembersComponent]
})
export class InviteMembersComponentModule {}
