import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';
// declare let Meteor;

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  version;
  date: any = null;
  build: any = null;
  env = '';

  constructor(public http: HttpClient) {

    this.http.get('../assets/config.json')
    .subscribe((res: any) => {

      console.log('the config:', res);
      this.date = new Date(res.date);
      this.build = res.build;
      this.version = res.version;

      this.env = environment.env;

      // Raven.setRelease(this.version);
      // Raven.setEnvironment(environment.env);
      Sentry.init({
        dsn: 'https://5cf3ec9c6f7148c692d36e05862dd1d3@sentry.io/289176',
        release: 'clubezy@' + this.version,
        environment: this.env,
        defaultIntegrations: false
      });
      // Sentry.captureMessage('Something went wrong');
    });
  }
}
