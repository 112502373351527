import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { EventSelectListComponent } from './event-select-list.component';
import { PipesModule } from '../../_pipes/pipes.module';
import { OrderModule } from 'ngx-order-pipe';
import { MomentModule } from 'ngx-moment';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    OrderModule,
    MomentModule,
    IonCustomScrollbarModule
  ],
  declarations: [EventSelectListComponent],
  exports: [EventSelectListComponent]
})
export class EventSelectListComponentModule {}
