import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'searchNames',
  pure: false
})
export class SearchNamesPipe implements PipeTransform {

  transform(value: any, term: string, isRego: boolean = false) {
    if (term === undefined) {
      term = '';
    }
    const _terms = term.toLowerCase().split(' ');

    return value.filter((item: any) => {
      if (isRego) {
        return (item.profile && (item.profile.name.givenName && _terms.some(_term => item.profile.name.givenName.toLowerCase().includes(_term)) ||
        item.profile.name.lastName && _terms.some(_term => item.profile.name.lastName.toLowerCase().includes(_term)) ||
        (item.profile.contact.email && _terms.some(_term => item.profile.contact.email.toLowerCase().includes(_term))))) ||
        (item.name && (item.name.givenName && _terms.some(_term => item.name.givenName.toLowerCase().includes(_term)) ||
        item.name.lastName && _terms.some(_term => item.name.lastName.toLowerCase().includes(_term)) ||
        (item.contact.email && _terms.some(_term => item.contact.email.toLowerCase().includes(_term)))));
      } else {
        return item.profile.firstName && _terms.some(_term => item.profile.firstName.toLowerCase().includes(_term)) ||
        item.profile.lastName && _terms.some(_term => item.profile.lastName.toLowerCase().includes(_term)) ||
        (item.emails && _terms.some(_term => item.emails.some((email: any) => email.address.toLowerCase().includes(_term)))) ||
        (item.displayRoles && _terms.some(_term => item.displayRoles.some((role) => role.toLowerCase() === _term))) ||
        (item.groups && _terms.some(_term => item.groups.some((group) => group.name.toLowerCase().includes(_term) || group.shortName.toLowerCase().includes(_term))));
      }
    });
  }

}
