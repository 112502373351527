export const environment: any = {
  meteorURI: 'https://clubezy.gravityfusion.com.au',
  meteorURIdev: 'https://clubezy-dev.gravityfusion.com.au',
  production: true,
  env: 'live',
  platform: 'pwa',
  firebase: {
    apiKey: 'AIzaSyChlFjbHZtRMYFotSdkpwJJNorothcxzz0',
    projectId: 'clubezy-170000',
    storageBucket: 'clubezy-170000.appspot.com',
    appId: '1:848966843507:web:244741beeb41652e'
  },
  oneSignal: {
    appId: '11e2db98-bb8c-438b-9111-ab4da051c162'
  },
  intercom: {
    appId: 't2mwwy9r'
  },
  appId: {
    pkg: 'com.totallyezy.clubezy',
    android: 'com.totallyezy.clubezy',
    ios: '1466713223'
  }
};


// setup environment files to be changes based on the environment selected when building
