<ion-header>

  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button (click)="close()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="group">Invite To {{group?.name}}</ion-title>
    <ion-title *ngIf="chat">Invite To {{chat?.title}}</ion-title>
    <ion-title *ngIf="event?.name">Invite To {{event?.name}}</ion-title>
    <ion-title *ngIf="newEvent && !event.name">Invite To event</ion-title>
    <ion-title *ngIf="newChat">Invite To New Chat</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="invite()">
          <!-- <ion-icon name="person-add"></ion-icon> -->
          Save
      </ion-button>
    </ion-buttons>
   </ion-toolbar>
        <ion-searchbar
          [(ngModel)]="searchInput"
          showCancelButton="focus"
          (ionChange)="setFilters()"
          debounce="500"
          inputmode="text">
        </ion-searchbar>
   <ion-toolbar>
   <ion-item>
      <ion-label>Group</ion-label>
      <ion-select slot="end" [(ngModel)]="groupFilter" (ionChange)="setFilters()">
        <ion-select-option *ngFor="let aGroup of groups | orderBy:'maxAge'" [value]="aGroup">{{aGroup.shortName?aGroup.shortName:aGroup.name}}</ion-select-option>
      </ion-select>
    </ion-item>
  </ion-toolbar>
</ion-header>

<ion-content scrollbar>
  <!-- <ion-list no-padding> -->
  <ion-virtual-scroll [items]="filteredMembers" [approxItemHeight]="'40px'">
      <!-- <ion-item *ngFor="let member of filteredMembers | searchNames: searchInput" detail="false"> -->
      <ion-item *virtualItem="let member" detail="false">
        <ion-avatar slot="start">
          <ion-img [src]="member.profile.images.thumb"></ion-img>
        </ion-avatar>
        <ion-label>
          <span *ngIf="member.profile.organisation[selectedSeason._id]?.player?.playingNumber">{{member.profile.organisation[selectedSeason._id].player.playingNumber}} | </span>{{member.profile.firstName}} {{member.profile.lastName}}
          <!-- {{member.profile.firstName}} {{member.profile.lastName}} -->
          <p *ngIf="member.emails">{{member.emails[0].address}}</p>
        </ion-label>
        <ion-note slot="end" *ngIf="member._id==user._id">You</ion-note>
        <!--<ion-checkbox color="dark" checked="member.selected"></ion-checkbox>-->
        <ion-toggle slot="end" [disabled]="(chat || newChat) && member._id==user._id" [(ngModel)]="member.selected" (ngModelChange)="addToLists(member)"></ion-toggle>
      </ion-item>
    <!-- </ion-list> -->
  </ion-virtual-scroll>
</ion-content>
