import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'isInGroup',
  pure: false
})
export class IsInGroupPipe implements PipeTransform {

  transform(value: any, term: any) {
    if (term === undefined || term.organisation === undefined) {
      return value;
    }
    return value.filter((item: any) => term.members.some((x) => x.memberId === item._id ));
  }

}
