import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { MeteorObservable } from 'meteor-rxjs';
import * as Sentry from '@sentry/browser';
import { BehaviorSubject, combineLatest, from, Observable, Subject, timer } from 'rxjs';
import { debounce, map, tap } from 'rxjs/operators';
import { CEAnnouncements, CEChats, CEGroups, CEMessages } from '../../module/collections/clubezy-collections';
import { TermsComponent } from '../_components/terms/terms.component';
import { PresentersService } from './presenters.service';
import { ProfileService } from './profile.service';
import * as moment from 'moment';
import * as momentZone from 'moment-timezone';
import { environment } from 'src/environments/environment';
import { Intercom } from 'ng-intercom';
import { UtilService } from './util.service';
import { ConfigService } from './config.service';

// import { Meteor } from 'meteor/meteor';
// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Meteor: any;


/*
  Generated class for the Activities provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {


  afterLogout: Observable<any>;
  beforeLogout: Observable<any>;
  afterLogin: Observable<any>;

  _afterLogout: BehaviorSubject<any> = new BehaviorSubject(false);
  _beforeLogout: BehaviorSubject<any> = new BehaviorSubject(false);
  _afterLogin: BehaviorSubject<any> = new BehaviorSubject(false);

  loggedIn = false;

  webOneSignal: any;
  showingOnesignalPrompt = false;

  openedFromNotification: any = false;

  groupUsers = [];

  groups = [];

  // organisationList = [];
  // organisationDisplayList = [];
  seasonList: Observable<any[]>;
  pendingSeasonInvites: Observable<any[]>;
  archivedSeasonsList: Observable<any[]>;
  seasonsForOrg: Observable<any[]>;
  // _seasonsSubject = new Subject<any[]>();

  _seasonList: BehaviorSubject<any[]> = new BehaviorSubject([] as any);
  _pendingSeasonInvites: BehaviorSubject<any[]> = new BehaviorSubject([] as any);
  _archivedSeasonsList: BehaviorSubject<any[]> = new BehaviorSubject([] as any);
  _seasonsForOrg: BehaviorSubject<any[]> = new BehaviorSubject([] as any);

  selectedOrganisationSeason: Observable<any[]>;
  _selectedOrganisationSeason: BehaviorSubject<any> = new BehaviorSubject({organisation: {}, season: {}});
  localSelectedOrganisation: any = {};
  localSelectedSeason: any = {};
  organisationAnnouncements: Observable<any[]>;
  _organisationAnnouncements: BehaviorSubject<any[]> = new BehaviorSubject([] as any);

  organisations: Observable<any[]>;
  _organisations: BehaviorSubject<any[]> = new BehaviorSubject([] as any);

  theDate: any = null;
  user: any;

  // selectedInvitesList: any = null;

  imagesUploading = 0;
  deviceId = '';

  lastUrlOpened;

  // announcePubSub;
  // statusPubSub;
  groupPubSub;
  // eventPubSub;
  // groupchatPubSub;
  chatPubSub;
  // userPubSub;
  orgPubSub;
  // busDirPubSub;
  // sponsorPubSub;
  // matchReportsPubSub;
  // gearPubSub;
  // assessmentTemplatePubSub;
  // rolesAssignmentPubSub;

  directChatUnseenSub;
  orgChatUnseenSub;

  // directUnreadCount = 0;
  // orgUnreadCount: ;
  announcementUnreadCount: Observable<number>;
  _announcementUnreadCount: BehaviorSubject<number> = new BehaviorSubject(0);
  directUnreadCount: Observable<number>;
  _directUnreadCount: BehaviorSubject<number> = new BehaviorSubject(0);
  orgUnreadCount: Observable<number>;
  _orgUnreadCount: BehaviorSubject<number> = new BehaviorSubject(0);

  indicatorSub;
  groupMembersSub;
  groupEventSub;
  announcementsSub;
  // seasonSub;
  // organisationSub;

  loginEventSubscription;
  logoutEventSubscription;

  defaultOrg;

  userTimezone;

  roles = [
    {
      name: 'Owner',
      actual: 'admin_3'
    },
    {
      name: 'Administrator',
      actual: 'admin_2'
    },
    {
      name: 'Registrar',
      actual: 'admin_1'
    },
    {
      name: 'Technical Director',
      actual: 'coach_3'
    },
    {
      name: 'Head Coach',
      actual: 'coach_2'
    },
    {
      name: 'Coach',
      actual: 'coach_1'
    },
    {
      name: 'Team Manager',
      actual: 'team_2'
    },
    {
      name: 'Team Support',
      actual: 'team_1'
    },
    {
      name: 'Player',
      actual: 'player'
    },
    {
      name: 'Parent',
      actual: 'parent'
    },
    {
      name: 'Supporter',
      actual: 'supporter'
    },
    {
      name: 'Trial',
      actual: 'trial'
    }
  ];

  settings: any = {};

  constructor(
    public profile: ProfileService,
    public presenters: PresentersService,
    public util: UtilService,
    public cordovaOneSignal: OneSignal,
    public platform: Platform,
    public intercom: Intercom,
    public config: ConfigService
    ) {

    this.profile.reloging.subscribe((val) => {
      if (val) {
        this.loginSubscriptions();
      }
    });

    this.onInit();

  }

  onInit() {

    this.userTimezone = momentZone.tz.guess();
    console.log(this.userTimezone);
    // this.userPubSub = MeteorObservable.subscribe('users').subscribe((sub) => {
    //   console.log('user unlogged sub', sub);
    // });

    this.seasonList = this._seasonList.asObservable();
    this.pendingSeasonInvites = this._pendingSeasonInvites.asObservable();
    this.seasonsForOrg = this._seasonsForOrg.asObservable();
    this.archivedSeasonsList = this._archivedSeasonsList.asObservable();

    this.afterLogout = this._afterLogout.asObservable();
    this.beforeLogout = this._beforeLogout.asObservable();
    this.afterLogin = this._afterLogin.asObservable();

    this.announcementUnreadCount = this._announcementUnreadCount.asObservable();
    this.directUnreadCount = this._directUnreadCount.asObservable();
    this.orgUnreadCount = this._orgUnreadCount.asObservable();

    this.organisationAnnouncements = this._organisationAnnouncements.asObservable();
    this.organisations = this._organisations.asObservable();
    this.selectedOrganisationSeason = this._selectedOrganisationSeason.asObservable();
    // this.selectedSeason = this._selectedSeason.asObservable();
    this.localSelectedOrganisation = {};
    this.localSelectedSeason = {};
    this.theDate = moment().endOf('week').endOf('day');

    this.profile.user.subscribe((usr: any) => {
      this.user = usr;
      // console.log(this.user);
      if (this.user) {
        // console.log('activities - user sub', this.user);
        this.defaultOrg = this.user.profile.lastOrg;
        // if (this.user.profile.lastOrg) {
        //   this.defaultOrg = this.user.profile.lastOrg;
        // } else {

        // }
      } else {
        console.log('activities - user sub no user', this.user);
        if(this.loggedIn) {
          this.logout();
        }
      }
    });

    MeteorObservable.call('admin.publicSettings').subscribe((settingsSub) => {
      this.settings = settingsSub;
    });

  }

  logout() {

    // this.events.publish('beforeLogout');
    this._beforeLogout.next(true);
    // const logOutLoader = this.loadingCtrl.create({
    //   content: 'Logging Out...'
    // });
    // logOutLoader.present();
    // const logOutLoader =
    this.presenters.presentLoader({
      message: 'Logging Out...'
    });
    // this.user = {};
    console.log('activities logout event');

    this.groupUsers = [];
    this._selectedOrganisationSeason.next({
      organisation: {},
      season: {}
    });
    // this._selectedSeason.next({});
    this.localSelectedOrganisation = {};
    this.localSelectedSeason = {};

    this._seasonList.next([]);
    this._pendingSeasonInvites.next([]);
    this._archivedSeasonsList.next([]);
    this._seasonsForOrg.next([]);

    if (this.indicatorSub) {
      this.indicatorSub.unsubscribe();
    }
    if (this.announcementsSub) {
      this.announcementsSub.unsubscribe();
    }
    // if (this.seasonSub) {
    //   this.seasonSub.unsubscribe();
    // }
    // if (this.organisationSub) {
    //   this.organisationSub.unsubscribe();
    // }
    if (this.directChatUnseenSub) {
      this.directChatUnseenSub.unsubscribe();
    }
    if (this.orgChatUnseenSub) {
      this.orgChatUnseenSub.unsubscribe();
    }
    if(this.orgPubSub) {
      this.orgPubSub.unsubscribe();
    }
    if(this.groupPubSub) {
      this.groupPubSub.unsubscribe();
    }
    if(this.chatPubSub) {
      this.chatPubSub.unsubscribe();
    }
    console.log('activities logout event 1');

      if (this.deviceId !== '') {
        MeteorObservable.call('user.clearDevice',
          this.deviceId).subscribe((data2) => {
            console.log(`device cleared - ${JSON.stringify(data2)}`);
          });
      }
      if (this.profile.isMasqued === false) {
        this.onesignalLogout();
      }

    this.finalLogout();

  }

  finalLogout() {
    /******** fusion logout
    MeteorObservable.call('auth.logout', this.profile.authObj.token).subscribe((logoutSub: any) => {
      console.log('auth.logout', logoutSub);
    });
    ********** */
   this.intercom.shutdown();
   this.intercom.boot({
     // eslint-disable-next-line @typescript-eslint/naming-convention
     app_id: environment.intercom.appId,
     widget: {
       activator: '#intercom'
     },
     platform: this.platform.platforms(),
     version: this.config.version,
     build: this.config.build
   });
    Meteor.logout(() => {
      // Raven.setUserContext();
      Sentry.configureScope((scope) => {
        scope.setUser({});
      });
      // this.toggleOrgSelect();
      // logOutLoader.dismiss();
      this.presenters.dismissLoader();
      // this.events.publish('afterLogout');
      this._afterLogout.next(true);
      this.loggedIn = false;

      /// TODO: ADD REMOVING TOKEN FROM FUSIONAUTH
    });
  }

  loginSubscriptions() {
    this.presenters.dismissLoader();
    // this.profile.userSubscription();
    this.loggedIn = true;
    this.refreshSeasonList();
    this.resetLogin();
    console.log('activities loginSubscriptions event');
  }

  changeOrgSubscriptions(orgId, seasonId) {
    console.log(orgId, seasonId);
    console.log('changeOrgSub');
    // season.getMySeasonsList
    if(this.orgPubSub) {
      this.orgPubSub.unsubscribe();
    }
    this.orgPubSub = MeteorObservable.subscribe('OrgAndSeason', orgId, seasonId).pipe(
      tap(() => {
        console.log ('publication subs done');

        if(this.groupPubSub) {
          this.groupPubSub.unsubscribe();
        }
        if(this.chatPubSub) {
          this.chatPubSub.unsubscribe();
        }
        this.groupPubSub = MeteorObservable.subscribe('groups', orgId).subscribe();
        // this.announcePubSub = MeteorObservable.subscribe('announcements').subscribe();
        // this.eventPubSub = MeteorObservable.subscribe('events').subscribe();
        this.chatPubSub = MeteorObservable.subscribe('chats', orgId).subscribe();
        // this.eventPubSub = MeteorObservable.subscribe('events').subscribe();
        // this.busDirPubSub = MeteorObservable.subscribe('businessDirectory').subscribe();
        // this.sponsorPubSub = MeteorObservable.subscribe('sponsors').subscribe();
        // this.matchReportsPubSub = MeteorObservable.subscribe('matchReports').subscribe();
        // this.gearPubSub = MeteorObservable.subscribe('gear').subscribe();
        // this.userPubSub = MeteorObservable.subscribe('users').subscribe();
        // this.loggedIn = true;
        // return from('Subs Done');
      })
    ).subscribe();
  }

  afterLoginSubscriptions() {
    // this.assessmentTemplatePubSub = MeteorObservable.subscribe('assessmentTemplates').subscribe();
    // MeteorObservable.subscribe('OrgsAndSeasons').subscribe();
  }

  login(email, password, success, failure) {
      // this.loading = this.loadCtrl.create(
    this.presenters.presentLoader({
      message: 'Loading...'
    });
    // this.loading.present();

    console.log(`EMail:  ${email}`);

    const msgChange = setTimeout(() => {
      this.presenters.dismissLoader();
      this.presenters.presentLoader({
        message: 'Still Loading...'
      });
    }, 20000);
    const timeout = setTimeout(() => {
      failure({reason: 'Login timed out, please try again later.'});
    }, 60000);

/*













*/
// USERNAME/EMAIL LOGIN
    Meteor.loginWithPassword(email, password, (response) => {
      clearTimeout(msgChange);
      clearTimeout(timeout);
      if (response) {
        failure(response);
      } else {
        success(response);
      }
    });
/********** fusion auth logging in
    const successCodes = [200, 202, 203, 212, 242];

    MeteorObservable.call('auth.login', email, password).subscribe((loginSub: any) => {
      console.log(loginSub);
      if (successCodes.includes(loginSub.statusCode)) {
        success(loginSub);
      } else {

        if (loginSub.statusCode === 404) {
          Meteor.loginWithPassword(email, password, (response) => {
            if (response) {
              failure(response);
            } else {
              // console.log(Meteor.user().profile.dob.split('T')[0]);
              /// CHECK DATE OF BIRTH, WRITE SCRIPT TO CONVERT ALL DOB TO THE SAME FORMAT
              const dob = Meteor.user().profile.dob ? Meteor.user().profile.dob.split('T')[0] : null;
              MeteorObservable.call('auth.createUser', email, password, dob, this.userTimezone).subscribe((createSub) => {
                console.log('created user', createSub);
                MeteorObservable.call('auth.login', email, password).subscribe((loginAfterCreate: any) => {
                  console.log(loginAfterCreate);
                  if (successCodes.includes(loginSub.statusCode)) {
                    success(loginAfterCreate);
                  } else {
                    failure(loginAfterCreate);
                  }
                });
              });
            }
          });
        } else {
          failure(loginSub);
        }
      }
    });
**********/
/*













*/
  }

  resetLogin() {
    console.log('activities login event');
    console.log(this.user);
    this.presenters.dismissLoader();
    if (this.user) {
      if (this.profile.isMasqued === false) {
        this.intercom.shutdown();
        this.intercom.boot({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          app_id: environment.intercom.appId,
          widget: {
            activator: '#intercom'
          },
          email: this.user.emails[0].address,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          user_id: this.user._id,
          platform: this.platform.platforms(),
          version: this.config.version,
          build: this.config.build
        });
      }
      // this.organisationSub = CEOrganisations.find({
        // if (this.seasonSub) {
        //   this.seasonSub.unsubscribe();
        // }
      // this.seasonSub = CESeasons.find({
      //   archived: false,
      //   members : {
      //     $elemMatch: {
      //       memberId: {$in:[this.user._id, ...this.user.profile.childAccounts]},
      //       accepted: {$eq: true},
      //       hidden: {$eq: false}
      //     }
      //   }
      // }, {
      //   fields: {
      //     members: 0
      //   },
      //   sort: {
      //     createdAt: -1
      //   }
      // }).pipe(
      //   debounce(() => timer(50))
      // )
      // this.refreshSeasonList();
      this.seasonList
      .subscribe((seasonListSub: any) => {
        // console.log('activities seasonList:', sub);
        //   // this.seasonList = sub;

        //   console.log(this.defaultOrg);
        //   console.log(this.seasonList.length);
        if (!this.defaultOrg && seasonListSub.length > 0) {
          console.log('no default org, has seasons');
          const activeSeason = seasonListSub.find((x) => x.active === true);
          // console.log(activeSeason);
          const seasonToUse = activeSeason ? activeSeason : this.seasonList[0];
          this.changeOrg(seasonToUse.organisationObj, seasonToUse);
          this.localSelectedSeason = {};

        } else if (!this.defaultOrg && seasonListSub.length === 0) {
          console.log('no default org, no seasons');
          this.changeOrg({}, {});

        } else {

          const orgSeasons = seasonListSub.filter((x) => x.organisation === this.defaultOrg);
          const activeSeason = orgSeasons.find((x) => x.active === true);
          // console.log(orgSeasons);
          // console.log(activeSeason);
          if (this.defaultOrg && orgSeasons.length > 0) {
            console.log('default org, has seasons');
            const seasonToUse = activeSeason ? activeSeason : orgSeasons[0];
            this.changeOrg(seasonToUse.organisationObj, seasonToUse);
          } else {
            console.log('default org, no seasons: why are you here?');
            // this.changeOrg(this.defaultOrg);
          }
        }

        // const orgIds = this.seasonList.map((season) => season.organisation);

        // this.organisationSub = CEOrganisations.find({
        //   $or: [
        //     {
        //       _id: {
        //         $in: orgIds
        //       }
        //     }, {
        //       ownership: this.user._id
        //     }
        //   ]
        // })
        // .subscribe((orgSub) => {
        //   this.organisationList = orgSub;
        // });

        if (this.profile.isMasqued === false) {
          this.onesignalChecks();
        }
        // this.events.publish('afterLogin');
        this._afterLogin.next(true);

      });
    }

  }

  refreshSeasonList(after?) {
    MeteorObservable.call('season.getMySeasonsList').pipe(
      tap((seasonsSub: any)=> {
        console.log('activities seasonList:', seasonsSub);

        const sl = [];
        const psl = [];
        const arch = [];
        const forOrg = [];

        seasonsSub.map((season) => {
          // console.log(season);
          if(season.archived) {
            arch.push(season);
          } else {
            if(season.organisation === this.user.profile.lastOrg) {
              forOrg.push(season);
            }
            if(season.organisationObj.ownership === this.user._id || season.members.find((mem) => mem.accepted)) {
              sl.push(season);
            }
            if(season.members.find((mem) => !mem.accepted)) {
              psl.push(season);
            }
          }

        });

        this._seasonList.next(sl);
        this._pendingSeasonInvites.next(psl);
        this._archivedSeasonsList.next(arch);
        this._seasonsForOrg.next(forOrg);

      })
    ).subscribe(() => {
      if(after) {
        after();
      }
    });
  }

  checkAndAdd(arr, obj) {
    const found = arr.some((el: any) => el.name === obj.name);
    if (!found) {
      arr.push(obj);
    }
  }

  onesignalChecks() {
    if (this.platform.is('cordova')) {
      this.setupOneSignal();
      this.cordovaOneSignal.addSubscriptionObserver().subscribe((state) => {
        console.log('to onesignal ID:', JSON.stringify(state));
        if (!state.from.subscribed && state.to.subscribed) {
          this.setupOneSignal();
          console.log('Subscribed for OneSignal push notifications!');
          // get player ID
          this.deviceId = state.to.userId;
          console.log('to onesignal ID:', state.to.userId);
          MeteorObservable.call('user.updateDevice',
          this.deviceId).subscribe((sub) => {
              console.log(`device saved ${this.deviceId} : ${JSON.stringify(sub)}`);
            });
          // }
        }
        console.log('Push Subscription state changed: ' + JSON.stringify(state));
      });
    } else {
      this.webOneSignal.push(() => {

        if (this.webOneSignal.isPushNotificationsSupported()) {
          this.setupOneSignal();

          this.webOneSignal.on('subscriptionChange', (isSubscribed) => {
            console.log('The user\'s subscription state is now:', isSubscribed);
            this.webOneSignal.getUserId().then((userId) => {
              console.log('User ID is', userId);
              this.deviceId = userId;
              MeteorObservable.call('user.updateDevice',
              userId).subscribe((sub) => {
                  console.log(`device saved ${userId} : ${JSON.stringify(sub)}`);
                });
              // }
            });
            if (isSubscribed) {
              this.setupOneSignal();
            }
          });
        } else {
          console.log('Push Notifications Not Supported');
        }
      });
    }
  }

  setupOneSignal() {

      // console.log('onesignal tags: ', this.oneSignalGroupTags);
      MeteorObservable.call('user.getMyTags').subscribe((myTags) => {
        console.log('myTags for onesignal', myTags);
        if (this.platform.is('cordova')) {
          //  .then((addTagRes) => {
          //   console.log('tags added: ', addTagRes);
          // });

          this.cordovaOneSignal.sendTags(myTags);
          this.cordovaOneSignal.getPermissionSubscriptionState().then((status: any) => {
            console.log('onesignal permission status: ', JSON.stringify(status));
            if(status) {
              if (status.subscriptionStatus.subscribed === true) {
                this.deviceId = status.subscriptionStatus.userId;
                MeteorObservable.call('user.updateDevice',
                this.deviceId).subscribe((sub) => {
                    console.log(`device saved ${this.deviceId} : ${JSON.stringify(sub)}`);
                  });
                } else {
                  if(this.platform.is('ios') && status.permissionStatus.hasPrompted === false) {
                    console.log(`I HAVE NOT PROMPTED WHY YOU NO WORK`);
                    this.cordovaOneSignal.addTrigger('prompt_ios', 'true');
                  }
                }
              }
            });
        } else {
          console.log('Notification: ', Notification);
          if (Notification) {
            console.log('Notification permission: ', Notification.permission);
            if (Notification.permission === 'granted') {
              console.log('Push notifications are enabled!');

              this.webOneSignal.push(() => {
                this.webOneSignal.sendTags(myTags, (addTagRes) => {
                  console.log('tags added: ', addTagRes);
                });
                this.webOneSignal.getUserId((data) => {
                  console.log('onesignal userID: ', data);
                  if (data) {
                    this.deviceId = data;
                    MeteorObservable.call('user.updateDevice',
                      data).subscribe((sub) => {
                        console.log(`device saved ${data} : ${JSON.stringify(sub)}`);
                      });
                    }
                  });
                this.webOneSignal.addListenerForNotificationOpened((data) => {
                  console.log('Received NotificationOpened:');
                  console.log(data);
                });
              });
            } else if (Notification.permission === 'default') {
              console.log('Push notifications are not enabled yet.', this.showingOnesignalPrompt);
              if (!this.showingOnesignalPrompt) {
                this.showingOnesignalPrompt = true;
                this.webOneSignal.push(() => {
                  this.webOneSignal.registerForPushNotifications();
                  this.webOneSignal.showHttpPrompt({force: true});
                });
              }
            } else {
              console.log('Push notifications are disabled.');
            }

            this.webOneSignal.push(() => {
            this.webOneSignal.on('notificationDisplay', (event) => {
              console.warn('OneSignal notification displayed:', event);
            });
            this.webOneSignal.on('notificationDissmiss', (event) => {
              console.warn('OneSignal notification dismissed:', event);
            });
          });
        } else {
          console.log('No Notification, are you on iOS?');
        }
      }
    });
  }

  oneSignalInit() {
    console.log('Init OneSignal');
    if (this.platform.is('cordova')) {
      console.log('OneSignal Cordova');
      const iosSettings = {
        kOSSettingsKeyAutoPrompt : false,
        kOSSettingsKeyInAppLaunchURL : false
      };

      this.cordovaOneSignal.startInit(environment.oneSignal.appId, environment.firebase.messagingSenderId);

      this.cordovaOneSignal.inFocusDisplaying(this.cordovaOneSignal.OSInFocusDisplayOption.None);
      this.cordovaOneSignal.iOSSettings(iosSettings);
      this.cordovaOneSignal.handleNotificationReceived().subscribe(() => {
      // do something when notification is received
        console.log('notification was recieved');
      });

      this.cordovaOneSignal.handleNotificationOpened().subscribe((noti) => {
        console.log('The notification: ', noti);
        // do something when a notification is opened
        console.log('notification was opened');
      });

      this.cordovaOneSignal.endInit();
    } else {
      // console.log('OneSignal Web');
      this.webOneSignal = window['OneSignal'] || [];
      console.log('OneSignal Web: check window["OneSignal"] exists: ', window['OneSignal']);

      this.webOneSignal.push(() => {
          this.webOneSignal.init({
            appId: environment.oneSignal.appId,
            autoRegister: false,
            autoResubscribe: true,
            allowLocalhostAsSecureOrigin: true,
            notificationClickHandlerMatch: 'origin',
            notificationClickHandlerAction: 'navigate',
            persistNotification: false,
            promptOptions: {
              actionMessage: 'Please allow us to send you notifications for announcements, events and discussions.',
              acceptButtonText: 'ALLOW',
              cancelButtonText: 'NO THANKS'
            },
            welcomeNotification: {
              title: 'ClubEzy',
              message: 'Thank for joining us.'
            }
          });

          this.webOneSignal.isPushNotificationsEnabled((isEnabled) => {
            console.log('isPushNotificationsEnabled returns: ', isEnabled);
          });
        });

        console.log('Native Notification.permission: ', typeof Notification !== 'undefined' ? Notification.permission : 'iOS, Not Supported');
      }

  }

  onesignalLogout() {
    if (this.platform.is('cordova')) {
      this.cordovaOneSignal.getTags().then((data) => {
        // console.log(data);
        const keys = Object.keys(data);
        this.cordovaOneSignal.deleteTags(keys);
      });
    } else {
      this.webOneSignal.push(() => {
        if (this.webOneSignal.isPushNotificationsSupported()) {
          // console.log('activities logout event 2.1');
          if (Notification.permission === 'granted') {
            // console.log('activities logout event 3.1');
            this.webOneSignal.getTags((data) => {
              // console.log(data);
              const keys = Object.keys(data);
              this.webOneSignal.deleteTags(keys, (delTags) => {
                // console.log('tags deleted', delTags);
              });
            });
          }
        }
      });
    }
  }

  changeOnesignalTag(tagId, tag) {
    if (this.platform.is('cordova')) {
      this.cordovaOneSignal.sendTag(tagId, tag);
    } else {
      this.webOneSignal.sendTag(tagId, tag);
    }
  }

  showTerms() {
    console.log('show terms', this.localSelectedOrganisation._id, this.user._id);
    if (this.selectedOrganisationSeason && this.user) {
      // console.log('show terms inside 1', this.localSelectedOrganisation.policies.length,
      // this.user.profile.organisations[this.localSelectedOrganisation._id][this.localSelectedSeason._id].admin);
      if (this.localSelectedOrganisation.policies.length > 0 &&
        (!this.user.profile.organisations[this.localSelectedOrganisation._id][this.localSelectedSeason._id].admin ||
        !this.user.profile.organisations[this.localSelectedOrganisation._id][this.localSelectedSeason._id].admin.mOUSigned)
        ) {
        console.log('should be showing terms ', );

        /// TODO: add a clubezy terms that needs to be done for clubezy usage, rather than for an organisation specific.
        /// might need a new admin object on the users profile for this.

          this.presenters.presentModal({
            component: TermsComponent,
            componentProps: {
              title: this.localSelectedOrganisation.shortName?this.localSelectedOrganisation.shortName:'Organisation' + ' Policies',
              message: this.localSelectedOrganisation.messages.policy,
              list: this.localSelectedOrganisation.policies,
              itemClick: ((url) => {
                this.util.visitLink(url);
              })
            },
            backdropDismiss: false
          }).then((data) => {
            // console.log(data);
            if (data.data) {
              MeteorObservable.call('user.acceptPolicies', this.localSelectedOrganisation._id, this.localSelectedSeason._id).subscribe(() => {

              });
            }
          });


      }

    }
  }

  changeOrg(org, season) {
    console.log('changeOrg');

    console.log(org);
    console.log(season);

    this._selectedOrganisationSeason.next({
      organisation: org._id? org : {},
      season: season._id? season : {}
    });
    this.localSelectedOrganisation = org._id? org : {};
    this.localSelectedSeason = season._id? season : {};
    // this.user.profile.lastOrg = org._id;
    this.intercom.update({
      organisation: org._id? org._id : '',
      season: season._id? season._id : ''
    });
    this.setMessageIndicators();
    this.setAnnouncementIndicators();
    if (org._id) {
      // this.rolesAssignmentPubSub = MeteorObservable.subscribe('roleAssignment', org._id).subscribe();
      // CESeasons.find({
      //   organisation: this.localSelectedOrganisation._id,
      //   archived: false
      // }, {sort: {year: 1}})
      // .pipe(
      //   debounce(() => timer(50))
      // )
      // .subscribe((sub) => {
      //     console.log('seasonsForOrg:', sub);
      //     this.seasonsForOrg = sub;
      // });

      // this.seasonList.subscribe((seasonListSub) => {
      //   this.seasonsForOrg = seasonListSub.filter((seas) => seas.organisation === org._id);
      // });

      this.changeOrgSubscriptions(org._id, season._id);
      this.profile.getMyGroupsImAdmin(org._id, season._id);
      // this.resetLogin();
      console.log(`Selected season:`, this.localSelectedSeason);
      console.log(`Selected org:`, this.localSelectedOrganisation);
      if (org._id !== this.user.profile.lastOrg) {

        // const logOutLoader = this.loadingCtrl.create({
        //   content: 'Changing Organisation...'
        // });
        // logOutLoader.present();
        this.refreshSeasonList();

        this.theDate = moment().endOf('week').endOf('day');
        // this.presenters.presentLoader({
        //   message: 'Changing Organisation...'
        // });
        MeteorObservable.call('user.changeLastOrgSeason', org._id, season._id).subscribe(() => {
          // this.events.publish('login');
          // this.loginSubscriptions();
          // this.presenters.dismissLoader();
          this.showTerms();
        });
      }
    }
  }

setAnnouncementIndicators() {
  if (this.localSelectedOrganisation !== undefined && this.localSelectedOrganisation._id !== undefined) {
    console.log('announcements=>', this.localSelectedOrganisation._id);
    this.announcementsSub = CEAnnouncements.find({
      organisation: this.localSelectedOrganisation._id
    },
      {
        sort: {
          createdAt: -1
        }
      }).pipe(
      // .debounce(() => Observable.interval(50))
        map((messages: any) => {
          let announcementUnread = 0;
          // console.log('announcements=>', messages);
          messages.forEach((message => {
            message.unseen = !message.seenBy.some((x) => x.member === this.user._id);
            if (message.unseen) {
              announcementUnread++;
            }
          }));
          this._announcementUnreadCount.next(announcementUnread);
          return messages;
        })
      ).subscribe((sub2: any) => {
        this._organisationAnnouncements.next(sub2);
      });
    } else {
      this._announcementUnreadCount.next(0);
    }
}

setMessageIndicators() {
  let groupSub;
  if (this.localSelectedSeason !== undefined && this.localSelectedSeason._id !== undefined ) {
    groupSub = CEGroups.find({
      season: this.localSelectedSeason._id
    }, {
      fields: {_id: 1}
    });
  } else {
    console.log('no season, cant get groups');
  }
  if(this.user._id) {
  const chatsSub = CEChats.find({
    organisation: this.localSelectedOrganisation._id,
    $or: [
      {
        members: {
          $elemMatch: { memberId: {$in: [this.user._id, ...this.user.profile.childAccounts]}, hidden: { $eq: false } }
        }
      }, {
        owner: this.user._id
      }
    ]
  });
  if(this.indicatorSub) {
    this.indicatorSub.unsubscribe();
  }
  this.indicatorSub = combineLatest([chatsSub, groupSub])
  .pipe(
      debounce(() => timer(200))
  )
      .subscribe((lists: any) => {

        this.groups = lists[1].map((group: any) =>  group._id);

        const season = [];
        const direct = [];
        lists[0].forEach((chat) => {

            if (!chat.group) {
              direct.push(chat._id);
            } else {
              if (this.localSelectedSeason._id && this.groups.includes(chat.group)) {
                season.push(chat._id);
              } else {
                /// TODO: add indicator that other seasons/organisations have messages
              }
            }
        });
            if (this.directChatUnseenSub) {
              this.directChatUnseenSub.unsubscribe();
            }
            if (this.orgChatUnseenSub) {
              this.orgChatUnseenSub.unsubscribe();
            }
            this.directChatUnseenSub = CEMessages.find({
              chatId: {$in: direct},
              'seenBy.member': {$ne: this.user._id},
              type: {$ne: 'system'}
            }, {
              sort: { createdAt: -1 },
              limit: 11
            }).pipe(
              debounce(() => timer(200))
            ).subscribe((directUnreadCount) => {
              // console.log('directUnreadCount', directUnreadCount);
              this._directUnreadCount.next(directUnreadCount.length);
            });

            this.orgChatUnseenSub = CEMessages.find({
              chatId: {$in: season},
              'seenBy.member': {$ne: this.user._id},
              type: {$ne: 'system'}
            }, {
              sort: { createdAt: -1 },
              limit: 11
            }).pipe(
              debounce(() => timer(200))
            ).subscribe((orgUnreadCount) => {
              // console.log('orgUnreadCount', orgUnreadCount);
              this._orgUnreadCount.next(orgUnreadCount.length);
            });

      });
    } else {
      this._directUnreadCount.next(0);
      this._orgUnreadCount.next(0);
    }
}

}



