import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MenuPopoverComponent } from './menu-popover.component';
import { PipesModule } from '../../_pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule
  ],
  declarations: [MenuPopoverComponent],
  exports: [MenuPopoverComponent]
})
export class MenuPopoverComponentModule {}
