import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { LastActiveAtComponent } from './last-active-at.component';
import { MomentModule } from 'ngx-moment';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    MomentModule
  ],
  declarations: [LastActiveAtComponent],
  exports: [LastActiveAtComponent]
})
export class LastActiveAtComponentModule {}
