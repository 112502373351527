import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ActivitiesService } from './activities.service';
// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Meteor: any;

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    public navCtrl: NavController,
    public activities: ActivitiesService) {

  }

  canActivate(route: ActivatedRouteSnapshot): boolean {

      // console.log(route);

      this.activities.lastUrlOpened = route.url;
      // console.log(this.activities.lastUrlOpened);
      if (!Meteor.user()) {
          console.log('no user, bounce');
          this.navCtrl.navigateRoot(['login']);
          return false;
      }

      // Run the login commands here if the services have not been prepped;


      // commentd out for now until subscriptions for curent organisation
      // refreshing a page stays on the page, but the source doesnt work
      // until the activities is properly prepared much of whch relies on the organisation
      // if (!this.activities.loggedIn) {
      //   this.activities.beforeLogin().subscribe();
      // }

      if (!this.activities.loggedIn) {
        console.log('no logged in, bounce');
        this.navCtrl.navigateRoot(['login']);
        return false;
      }
      return true;

  }

}
