<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{title}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content scrollbar>
  <ion-list class="ion-no-padding">
    <ion-item><ion-label class="ion-text-wrap">{{message}}</ion-label></ion-item>
    <ion-button class="ion-text-wrap" *ngFor="let item of list" fill="clear" size="small" strong expand="block" (click)="itemClick(item.url)">{{item.name}}</ion-button>
    <ion-button expand="block" (click)="accept()">Accept</ion-button>
  </ion-list>
</ion-content>
