import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SeasonInvitesComponent } from './season-invites.component';
import { PipesModule } from '../../_pipes/pipes.module';
import { MomentModule } from 'ngx-moment';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    MomentModule,
    IonCustomScrollbarModule
  ],
  declarations: [SeasonInvitesComponent],
  exports: [SeasonInvitesComponent]
})
export class SeasonInvitesComponentModule {}
