import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PresentersService } from '../../_services/presenters.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermsComponent implements OnInit {

  list = [];
  message = '';
  title = '';
  itemClick;

  constructor(public presenters: PresentersService) {

  }

  ngOnInit() {
    // this.list = this.navParams.get('list');
    // this.message = this.navParams.get('message');
    // this.title = this.navParams.get('title');
    // this.itemClick = this.navParams.get('itemClick');
  }

  close() {
    this.presenters.dismissModal();
  }

  accept() {
    this.presenters.dismissModal(true);
  }

}
