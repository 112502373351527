import { ChangeDetectorRef, Component, ViewChild, ViewEncapsulation, OnInit, Renderer2, NgZone } from '@angular/core';
import { IonRouterOutlet, MenuController, NavController, Platform } from '@ionic/angular';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivitiesService } from './_services/activities.service';
import { ConfigService } from './_services/config.service';
import { PresentersService } from './_services/presenters.service';
import { ProfileService } from './_services/profile.service';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, debounce, tap } from 'rxjs/operators';
import { combineLatest, from, timer } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { SeasonInvitesComponent } from './_components/season-invites/season-invites.component';
import { Intercom } from 'ng-intercom';
import { environment } from 'src/environments/environment';
import { UtilService } from './_services/util.service';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
// import { Market } from '@ionic-native/market/ngx';
import { MeteorObservable } from 'meteor-rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Market: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('openClose', [
      state('open', style({
        // height: '100%'
        transform: 'translateY(0)'
      })),
      state('closed', style({
        // height: '0'
        transform: 'translateY(-100%)'
      })),
      transition('open <=> closed', [
        animate('0.2s')
      ])
    ])
  ]
})
export class AppComponent implements OnInit {

  @ViewChild(IonRouterOutlet, { static: false }) _router: IonRouterOutlet;
  // @ViewChild('menuHandler') menuHandler: IonContent;

  // rootPage: any = LoginPage;
  user: any;

  pagesCalendar: Array<{ title: string; component: any; disabled: boolean; routeData?: any; badge?: any; hidden?: boolean }> = [];
  // pagesEzy: Array<{ title: string, component: any, disabled: boolean, routeData?: any, badge?: any }> = [];
  pagesHome: Array<{ title: string; component: any; disabled: boolean; routeData?: any; badge?: any; hidden?: boolean }> = [];
  pagesMyClub: Array<{ title: string; component: any; disabled: boolean; routeData?: any; badge?: any; hidden?: boolean }> = [];
  pagesTrials: Array<{ title: string; component: any; disabled: boolean; routeData?: any; badge?: any; hidden?: boolean }> = [];
  pagesMessages: Array<{ title: string; component: any; disabled: boolean; routeData?: any; badge?: any; hidden?: boolean }> = [];
  // pagesWallet: Array<{ title: string, component: any, disabled: boolean, routeData?: any, badge?: any }> = [];
  pagesSponsors: Array<{ title: string; component: any; disabled: boolean; routeData?: any; badge?: any; hidden?: boolean }> = [];
  pagesGear: Array<{ title: string; component: any; disabled: boolean; routeData?: any; badge?: any; hidden?: boolean }> = [];

  version: any = '';

  clubShortName = '';
  selectedMenu = 'home';
  showOrgSelect = false;

  pendingSeasonInvites = [];

  selectedOrganisation: any = {};
  selectedSeason: any = {};

  seasonList = [];

  orgSeaSub;
  seasonInvitesSub;
  pendingSeasonInvitesSub;

  backButtonPressedOnceToExit = false;

  constructor(public platform: Platform,
              public menu: MenuController,
              public config: ConfigService,
              public profile: ProfileService,
              public activities: ActivitiesService,
              public navCtrl: NavController,
              public router: Router,
              public swUpdate: SwUpdate,
              public presenters: PresentersService,
              public refs: ChangeDetectorRef,
              public util: UtilService,
              public renderer: Renderer2,
              private ngZone: NgZone,
              public intercom: Intercom,
              public deeplinks: Deeplinks,
              public navController: NavController,
              private appVersion: AppVersion) {

      console.log('isCordova', this.platform.is('cordova'));
      if (!this.platform.is('cordova')) {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('/OneSignalSDKWorker.js')
          .then(() => console.log('service worker installed'))
          .catch(err => console.log(err));
        }
      } else {
          window.open = (url, target?, opts?) =>  {
            this.util.visitLink(url);
            return null;
          };
      }

      this.initializeApp();

      this.intercom.boot({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        app_id: environment.intercom.appId,
        widget: {
          activator: '#intercom'
        },
        platform: this.platform.platforms(),
        version: this.config.version,
        build: this.config.build
      });

      this.router.events.pipe(
        distinctUntilChanged((previous: any, current: any) => {
        // Subscribe to any `NavigationEnd` events where the url has changed
        if (current instanceof NavigationEnd) {
            return previous.url === current.url;
        }
        return true;
      })
      ).subscribe(event => {
        // console.log('router event: ', event);
        (window as any).gtag('config', 'UA-93880244-2', {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          page_path: event.urlAfterRedirects
        });
    });

  }

  initializeApp() {

    this.platform.ready().then(() => {
      setTimeout(() => {
        this.renderer.addClass(document.body, 'body-background-colour');
      }, 5000);

      this.activities.oneSignalInit();
      this.setUpApp();

      this.platform.backButton.subscribe(() => {
        if (this.backButtonPressedOnceToExit) {
          navigator['app'].exitApp();
        } else if (this._router.canGoBack()) {
          // this.nav.pop({});
          this.navCtrl.back();
        } else {
          this.showToast();
          this.backButtonPressedOnceToExit = true;
          setTimeout(() => {

            this.backButtonPressedOnceToExit = false;
          }, 2000);
        }
      });
      if (this.platform.is('cordova')) {

        this.deeplinks.routeWithNavController(this.navController,
        {}).subscribe(match => {
          // match.$route - the route we matched, which is the matched entry from the arguments to route()
          // match.$args - the args passed in the link
          // match.$link - the full link data
          console.log('Successfully matched route', match);
        }, nomatch => {
          // nomatch.$link - the full link data
          console.error('Got a deeplink that didn\'t match', nomatch);
        });

        combineLatest([
          from(this.appVersion.getAppName()),
          from(this.appVersion.getPackageName()),
          from(this.appVersion.getVersionCode()),
          from(this.appVersion.getVersionNumber()),
        ]).subscribe(([_name, _pkg, _code, _num]) => {
          console.log(_name, _pkg, _code, _num);

          // get version from APPSETTINGS DB
          // compare to current version
          // if not same, show alert to update, alert should open market to update
          this.updateAppCheck(_code);

        });

      } else {
        this.updateAppCheck(this.config.version);
      }
    });
  }

  updateAppCheck(code) {
    MeteorObservable.call('admin.appSettings').subscribe((settings: any) => {
      // compare versions
      console.log('appsettings:', settings);
      const ver = settings.versions;
      const testVer = settings.testVersions;
      if (this.platform.is('cordova')) {
        if (this.platform.is('ios')) {
          if(code !== ver.ios && code !== testVer.ios) {
            this.openMarket(environment.appId.ios);
          }
        } else if(this.platform.is('android')) {
          if(code.toString() !== ver.android && code.toString() !== testVer.android) {
            this.openMarket(environment.appId.android);
          }
        }
      } else {
        // if(code !== ver.pwa && code !== testVer.pwa) {
          if (this.swUpdate.isEnabled) {
            this.swUpdate.checkForUpdate().then((res) => {
              console.log(res);
            });
          }
      }
    }, (err) => {
      console.log(err);
    });
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {

        this.swUpdate.available.subscribe(() => {
          this.presenters.presentAlert({
            header: 'New version.',
            message: 'New version available. Reloading App.',
            buttons: [
              {
                text: 'Ok',
                handler: data => {
                  console.log('Reloading App clicked');
                  this.swUpdate.activateUpdate().then(() => {
                    this.util.forceReload();
                  });
                }
              }
            ]
          });
        });
    }

    this.activities.seasonList.pipe(
      tap((seasonSub) => {
        this.ngZone.run(() => {
          console.log(seasonSub);
          this.seasonList = seasonSub;
        });
      })
    )
    .subscribe();
  }

  setUpApp() {

    this.pagesCalendar = [
      { title: 'Events', component: '/event-list', routeData: {}, disabled: false}
    ];
    this.pagesHome = [
      {title: 'My Profile', component: '/personal-profile', routeData: {}, disabled: false},
      {title: 'Accreditations', component: '/my-accreditations', routeData: {}, disabled: false},
      {title: 'Settings', component: '/settings', routeData: {}, disabled: false},
      {title: 'Linked Accounts', component: '/account-links-list', routeData: {}, disabled: false}
    ];
    this.pagesMessages = [
      {
        title: 'My Chats',
        component: '/chat-list/mine',
        routeData: {},
        disabled: false,
        badge: 0
      }
    ];
    this.orgSeaSub = this.activities.selectedOrganisationSeason.subscribe((selectedOrgSeason: any) => {
      this.selectedOrganisation = selectedOrgSeason.organisation;
      this.selectedSeason = selectedOrgSeason.season;

      if (this.selectedOrganisation.name) {
/// ===========================================================
    /// controls the side menu display items and menu items
    this.pagesCalendar = [
      { title: 'Events', component: '/event-list', routeData: {}, disabled: false},
      { title: 'Match Reports', component: '/match-reports', routeData: {}, disabled: false}
    ];

    this.pagesGear = [
      {title: 'Gear Steward', component: '/gear-kit-list', routeData: {}, disabled: false},
      {title: 'Gear Shop', component: '/gear-shop-list', routeData: {}, disabled: false}
    ];

    this.pagesMyClub = [
      {title: 'About Us', component: '/org-details', routeData: {}, disabled: false},
      {title: 'Announcements', component: '/org-anouncements', routeData: {}, disabled: false, badge: 0},
      {title: 'Seasons', component: '/season-list', routeData: {}, disabled: false},
      {title: 'Groups', component: '/group-list', routeData: {}, disabled: false},
      {title: 'Members', component: '/season-member-list', routeData: {}, disabled: false}
    ];

    this.pagesTrials = [
    ];

    this.pagesMessages = [
      {
        title: 'My Chats',
        component: '/chat-list/mine',
        routeData: {},
        disabled: false,
        badge: 0
      },
      {
        title: (this.selectedOrganisation.shortName ? this.selectedOrganisation.shortName : this.selectedOrganisation.name) + ' Chats',
        component: '/chat-list/org',
        routeData: {},
        disabled: false,
        badge: 0
      }
    ];

    this.pagesSponsors = [
      { title: 'Business Directory', component: '/business-directory', routeData: {}, disabled: false},
      { title: 'Sponsors', component: '/sponsors', routeData: {}, disabled: false}
    ];
    /// ===========================================================
      } else {
        // this matches the ones above
        this.pagesCalendar = [
          { title: 'Events', component: '/event-list', routeData: {}, disabled: false}
        ];
        this.pagesHome = [
          {title: 'My Profile', component: '/personal-profile', routeData: {}, disabled: false},
          {title: 'Accreditations', component: '/my-accreditations', routeData: {}, disabled: false},
          {title: 'Settings', component: '/settings', routeData: {}, disabled: false},
          {title: 'Linked Accounts', component: '/account-links-list', routeData: {}, disabled: false}
        ];
        this.pagesMessages = [
          {
            title: 'My Chats',
            component: '/chat-list/mine',
            disabled: false,
            badge: 0
          }
        ];
      }

      if (this.user) {
        this.checkMenuPermissions();
        this.getSeasonInvites();
      }

    });


    this.profile.user.subscribe((usr: any) => {
      this.user = usr;
      if (this.user == null) {
        return;
      }
      this.checkMenuPermissions();
      this.getSeasonInvites();
    });

    this.activities.afterLogout.subscribe((val) => {
      if (val) {
        this.profile.loggingOut = false;
      }
    });
    this.activities.beforeLogout.subscribe((val) => {
      if (val) {
        this.profile.loggingOut = true;
        this.navCtrl.navigateRoot('/login');
      }
    });

    this.activities.afterLogin.subscribe((val) => {
      if (val) {
        console.log('after login', this.user);
        this.presenters.dismissLoader();
        if (this.user == null) {
          return;
        }

        if ( this.pagesMessages[1]) {
          this.pagesMessages[1].title = (this.selectedOrganisation.shortName ? this.selectedOrganisation.shortName : this.selectedOrganisation.name) + ' Chats';
        }

        combineLatest([this.activities.orgUnreadCount, this.activities.directUnreadCount, this.activities.announcementUnreadCount])
        .subscribe(([orgUnread, directUnread, announceUnread]) => {
          if ( this.pagesMessages[1]) {
            this.pagesMessages[1].badge = orgUnread;
          }
          this.pagesMessages[0].badge = directUnread;
          if ( this.pagesMyClub[1]) {
            this.pagesMyClub[1].badge = announceUnread;
          }
        });
      }
    });

  }

checkMenuPermissions() {
  if (this.user.isAdmin || this.user.isOwner || this.user.isSuper) {

    if (!this.pagesMyClub.some((page: any) => page.title === 'Event Types')) {
      this.pagesMyClub.push({title: 'Event Types', component: '/manage-event-types', routeData: {}, disabled: false});
    }
    if (!this.pagesMyClub.some((page: any) => page.title === 'Assessment Templates')) {
      this.pagesMyClub.push({title: 'Assessment Templates', component: '/assessment-template-list', routeData: {}, disabled: false});
    }
    if (!this.pagesMyClub.some((page: any) => page.title === 'Reports')) {
      this.pagesMyClub.push({title: 'Reports', component: '/org-admin-reports', routeData: {}, disabled: false});
    }

    if (!this.pagesTrials.some((page: any) => page.title === 'Registration Desk')) {
      this.pagesTrials.push({title: 'Registration Desk', component: '/registrations-list', routeData: {}, disabled: false});
    }
    if (!this.pagesTrials.some((page: any) => page.title === 'New Trial Event')) {
      this.pagesTrials.push({title: 'New Trial Event', component: '/new-event', routeData: {isTrial: 'true'}, disabled: false});
    }

    if (!this.pagesGear.some((page: any) => page.title === 'Manage Gear')) {
      this.pagesGear.push({title: 'Manage Gear', component: '/gear-manage-list', routeData: {}, disabled: false});
    }
    if (!this.pagesGear.some((page: any) => page.title === 'Orders')) {
      this.pagesGear.push({title: 'Orders', component: '/gear-orders-admin', routeData: {}, disabled: false});
    }
  } else {

    if (this.pagesMyClub.some((page: any) => page.title === 'Event Types')) {
      this.pagesMyClub.splice(this.pagesMyClub.findIndex((page) => page.title === 'Event Types'), 1);
    }
    if (this.pagesMyClub.some((page: any) => page.title === 'Assessment Templates')) {
      this.pagesMyClub.splice(this.pagesMyClub.findIndex((page) => page.title === 'Assessment Templates'), 1);
    }
    if (this.pagesMyClub.some((page: any) => page.title === 'Reports')) {
      this.pagesMyClub.splice(this.pagesMyClub.findIndex((page) => page.title === 'Reports'), 1);
    }

    if (this.pagesTrials.some((page: any) => page.title === 'Registration Desk')) {
      this.pagesTrials.splice(this.pagesMyClub.findIndex((page) => page.title === 'Registration Desk'), 1);
    }
    if (this.pagesTrials.some((page: any) => page.title === 'New Trial Event')) {
      this.pagesTrials.splice(this.pagesMyClub.findIndex((page) => page.title === 'New Trial Event'), 1);
    }

    if (this.pagesGear.some((page: any) => page.title === 'Manage Gear')) {
      this.pagesGear.splice(this.pagesMyClub.findIndex((page) => page.title === 'Manage Gear'), 1);
    }
    if (this.pagesGear.some((page: any) => page.title === 'Orders')) {
      this.pagesGear.splice(this.pagesMyClub.findIndex((page) => page.title === 'Orders'), 1);
    }
  }

  if (this.user.isCoach || this.user.isSuper || this.user.isOwner) {
    if (!this.pagesTrials.some((page: any) => page.title === 'Trial Assessments')) {
      this.pagesTrials.push({title: 'Trial Assessments', component: '/trial-assessment-events-list', routeData: {}, disabled: false});
    }
    if (!this.pagesTrials.some((page: any) => page.title === 'Trial Reports')) {
      this.pagesTrials.push({title: 'Trial Reports', component: '/report-picker', routeData: {}, disabled: false});
    }
  } else {

    if (this.pagesTrials.some((page: any) => page.title === 'Trial Assessments')) {
      this.pagesTrials.splice(this.pagesMyClub.findIndex((page) => page.title === 'Trial Assessments'), 1);
    }
    if (this.pagesTrials.some((page: any) => page.title === 'Trial Reports')) {
      this.pagesTrials.splice(this.pagesMyClub.findIndex((page) => page.title === 'Trial Reports'), 1);
    }
  }

  this.refs.markForCheck();
  this.refs.detectChanges();
}

  showToast() {
    // const toast = this.toast.create(
    this.presenters.presentToast({
      message: 'Press Again to exit',
      duration: 2000,
      position: 'bottom'
    }).then((data) => {
      console.log('Dismissed toast');
    });
  }

  changeSeason(season) {
    console.log('change season called', season);
    this.showOrgSelect = false;
    this.navCtrl.navigateRoot('/org-details');
    this.activities.changeOrg(season.organisationObj, season);
  }

  openPage(page) {
    // Reset the content nav to have just this page
    // we wouldn"t want the back button to show in this scenario
    console.log(page);
    if (page.routeData) {
      this.navCtrl.navigateRoot([page.component, page.routeData]);
    } else {
      this.navCtrl.navigateRoot(page.component);
    }
  }

  signOut() {
    this.showOrgSelect = false;
    this.activities.logout();
  }

  menuClosed() {
    this.showOrgSelect = false;

  }

  menuOpened() {
    this.showOrgSelect = false;
  }

  splitChanged(e) {
    if (e.detail.visible) {
      this.menuOpened();
    } else {
      this.menuClosed();
    }
  }

  toggleOrgSelect() {
    this.showOrgSelect = !this.showOrgSelect;
    this.refs.markForCheck();
    this.refs.detectChanges();
  }

  gotoCreateOrg() {
    this.navCtrl.navigateRoot(['/new-organisation', {new: true}]);
  }

  getSeasonInvites() {

    // const userIds = [ this.user._id, ...this.user.profile.childAccounts ];
    const users = [this.user, ...this.profile.childrenArr];
    if (this.pendingSeasonInvitesSub) {
      this.pendingSeasonInvitesSub.unsubscribe();
    }
    this.pendingSeasonInvitesSub = this.activities.pendingSeasonInvites.subscribe((sub) => {
      this.pendingSeasonInvites = users.filter((user) => {
          user.invites = sub.filter((season) => season.members.some((member) => member.memberId === user._id && member.accepted === false ));
          return user.invites.length > 0;
        }).map((user) => {

          user.invites.map((invite) => {
            invite.orgName = invite.organisationObj.name;
            const invited = invite.members.find((member) => member.memberId === user._id );
            invite.inviteDate = invited ? invited.added : '';
          });

          return user;
        });
        console.log('pendingSeasonInvites: ', this.pendingSeasonInvites);
    });
  //   /*************************
  //    *
  //    * MOVE THIS TO A SERVER CALL
  //    * AS THE ORGANISATION YOU ARE INVITED TO MAY NOT BE
  //    * AVALIABLE IF YOU ARE NOT YET PART OF IT
  //    * ..wait it might be as you are invited and part of the member list, just not
  //    * able to acess it....
  //    * hmmmm
  //   ****************************/
  //   console.log('getSeasonInvites');
  //   if (this.seasonInvitesSub) {
  //     this.seasonInvitesSub.unsubscribe();
  //   }

  //   const userIds = [ this.user._id, ...this.user.profile.childAccounts ];
  //   const users = [this.user, ...this.profile.childrenArr];

  //   this.seasonInvitesSub = CESeasons.find({
  //     members : {
  //       $elemMatch: {
  //         memberId: {$in: userIds},
  //         accepted: {$eq: false},
  //         hidden: {$eq: false}
  //       }
  //     }
  //   }).pipe(
  //     debounce(() => timer(50))
  //   )
  //   .subscribe((sub: any) => {
  //     console.log('seaon invites: ', userIds, sub);
  //     this.pendingSeasonInvites = users.filter((user) => {
  //       user.invites = sub.filter((season) => season.members.some((member) => member.memberId === user._id && member.accepted === false ));
  //       return user.invites.length > 0;
  //     }).map((user) => {

  //       user.invites.map((invite) => {
  //         const orgName = CEOrganisations.findOne({_id: invite.organisation});
  //         invite.orgName = orgName ? orgName.name : '';
  //         const invited = invite.members.find((member) => member.memberId === user._id );
  //         invite.inviteDate = invited ? invited.added : '';
  //       });

  //       return user;
  //     });
  //     console.log('pendingSeasonInvites: ', this.pendingSeasonInvites);
  //   });
  }

  showOrgInvites() {
    // we willopen a modal with the invites that can be accepted here
    // modal or page?
    // probably modal is fine.
    console.log('pending invs: ',this.pendingSeasonInvites);
    this.presenters.presentModal({
      component: SeasonInvitesComponent,
      componentProps: {
        invites : this.pendingSeasonInvites,
        userId: this.user._id
      }
    }).then((data) => {
      console.log(data);
      if (data.data) {

      }
    });
  }

  // getOrgName(orgId) {
  //   const org = this.activities.organisationList.find((theOrg) => theOrg._id === orgId);
  //   return org? org.name : '';
  // }

  openMarket(pkg) {
    this.presenters.presentAlert({
      header: 'New version.',
      message: 'New version available, please Update.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Update Now',
          handler: data => {
            console.log('goto market clicked');
            Market.open(pkg);
          }
        }
      ]
    });
  }

  doSeasonRefresh(e) {

    this.activities.refreshSeasonList(() => {
      if(e) {
        e.target.complete();
      }
    });
  }

}


