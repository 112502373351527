import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AddPaymentComponent } from './add-payment.component';
import { PipesModule } from '../../_pipes/pipes.module';
import {NgxCurrencyModule} from 'ngx-currency';
import { DirectivesModule } from 'src/app/_directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    PipesModule,
    NgxCurrencyModule,
    DirectivesModule,
    IonCustomScrollbarModule
  ],
  declarations: [AddPaymentComponent],
  exports: [AddPaymentComponent]
})
export class AddPaymentComponentModule {}
