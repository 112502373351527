import { ProfileService } from './profile.service';
import { Injectable } from '@angular/core';
import { ActivitiesService } from './activities.service';
import { MeteorObservable } from 'meteor-rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccreditationService {

  accreditationURL;
  metaSub;
  user: any = {};
  accreditations = [];
  otherMember = false;

  constructor(
    public profile: ProfileService,
    public activities: ActivitiesService) {

  }

  getAccreditations(memberId) {
    if (memberId) {
      MeteorObservable.call('user.getUser', memberId).subscribe((user) => {
        // console.log(user);
        this.user = user[0];
        this.accreditations = this.user.profile.accreditations?this.user.profile.accreditations:[];
        console.log(this.user);
      });
      this.otherMember = true;
    } else {
      this.profile.user.subscribe((user) => {
        // console.log(user);
        this.user = user;
        this.accreditations = this.user.profile.accreditations?this.user.profile.accreditations:[];
        console.log(this.user);
      });
      this.otherMember = false;
    }
  }

}
