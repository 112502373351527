import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ToggleNotificationComponent } from './toggle-notification.component';
import { MomentModule } from 'ngx-moment';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    MomentModule,
    FormsModule
  ],
  declarations: [ToggleNotificationComponent],
  exports: [ToggleNotificationComponent]
})
export class ToggleNotificationComponentModule {}
