import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NewCriteriaComponent } from './new-criteria.component';
import { PipesModule } from '../../_pipes/pipes.module';
import { DirectivesModule } from 'src/app/_directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    DirectivesModule,
    IonCustomScrollbarModule
  ],
  declarations: [NewCriteriaComponent],
  exports: [NewCriteriaComponent]
})
export class NewCriteriaComponentModule {}
