import { Component, OnDestroy, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { MeteorObservable } from 'meteor-rxjs';
import { CEGroups, CEEvents, CEChats } from '../../../module/collections/clubezy-collections';
import { ActivitiesService } from '../../_services/activities.service';
import { PresentersService } from '../../_services/presenters.service';
import { ProfileService } from '../../_services/profile.service';
import { IsInGroupPipe } from '../../_pipes/is-in-group.pipe';
import { ActivatedRoute } from '@angular/router';
import { SearchNamesPipe } from '../../_pipes/search-names.pipe';
import { debounce } from 'rxjs/operators';
import { timer } from 'rxjs';

@Component({
  selector: 'app-invite-members',
  templateUrl: './invite-members.component.html',
  styleUrls: ['./invite-members.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InviteMembersComponent implements OnInit, OnDestroy {

  @Input() groupId: any;
  @Input() eventId: any;
  @Input() chatId: any;
  @Input() seasonId: any;

  user: any;
  members: any = [];
  filteredMembers: any = [];

  group: any;
  event: any;
  chat: any;

  newChat = false;
  newEvent = false;

  allOption = {shortName: 'All', maxAge: [0]};

  searchInput = '';
  groupFilter = this.allOption;
  groups: any = [this.allOption];

  profileSubscription;

  groupSubscription;
  season: any = {};
  toAdd = [];
  toRemove = [];

  selectedOrganisation: any = {};
  selectedSeason: any = {};
  orgSeaSub;

  constructor(
    public activities: ActivitiesService,
    public profile: ProfileService,
    public presenters: PresentersService,
    // public orderPipe: OrderPipe,
    public isInGroupPipe: IsInGroupPipe,
    public searchNamesPipe: SearchNamesPipe,
    public route: ActivatedRoute
    // public alertCtrl: AlertController,
    // public viewCtrl: ViewController
    ) {

  }

  ngOnInit() {
    // console.log(this.rounds);
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    this.profileSubscription = this.profile.user.subscribe((usr) => {
      this.user = usr;
    });

    if (this.groupId) {
      this.group = CEGroups.findOne({_id: this.groupId});
    } else if (this.eventId) {
      this.event = CEEvents.findOne({_id: this.eventId});
    } else if (this.chatId) {
      this.chat = CEChats.findOne({_id: this.chatId});
    }

    if (this.orgSeaSub) {
      this.orgSeaSub.unsubscribe();
    }

    this.orgSeaSub = this.activities.selectedOrganisationSeason.subscribe((selectedOrgSeason: any) => {
      this.selectedOrganisation = selectedOrgSeason.organisation;
      this.selectedSeason = selectedOrgSeason.season;

      // this.season = CESeasons.find({_id: this.seasonId}).fetch();
      if (this.groupSubscription) {
        this.groupSubscription.unsubscribe();
      }
      const groupObj: any = {organisation: this.selectedOrganisation._id, private: false};
      if (this.seasonId) {
        this.selectedSeason = this.activities.seasonsForOrg.subscribe((seasons) => {
          seasons.find((season) => season._id == this.seasonId);
        });
        // CESeasons.findOne({_id: this.seasonId});
        groupObj.season = this.seasonId;
      } else {

        groupObj.season = this.selectedSeason._id;
      }
      this.groupSubscription = CEGroups.find(groupObj, {sort: {maxAge: 1, name: 1}})
    .pipe(
      debounce(() => timer(150))
    )
    .subscribe((sub) => {
      this.groups = [this.allOption, ...sub];
      // console.log('the gorups: ', this.groups);
    });

    console.log('The Group: ', this.group);
    console.log('The Event: ', this.event);
    console.log('The Chat: ', this.chat);
    console.log('New Chat: ', this.newChat);
    console.log('New Event: ', this.newEvent);
    console.log('The Season: ', this.season);

      MeteorObservable.call('season.usersInSeason', this.seasonId).subscribe((resp: any) => {
        this.members = resp.map((mem) => {
            mem.profile.organisation = mem.profile.organisations[this.selectedOrganisation._id];
            if (this.group) {
              mem.selected = this.group.members.some((groupMem) => groupMem.memberId === mem.memberId);
            } else if (this.event) {
                mem.selected = this.event.members.some((eventMem) => eventMem.memberId === mem.memberId || eventMem._id === mem._id);
            } else if (this.chat) {
              mem.selected = this.chat.members.some((chatMem) => chatMem.memberId === mem.memberId);
            } else if (this.newChat) {
              if (mem.memberId === this.user._id) {
                mem.selected = true;
                // this.addToLists(mem._id, mem.selected);
                this.toAdd.push(mem.memberId);
              }
            }
            return mem;
          });
          // console.log('members before filter', this.members);
          this.setFilters();
          // console.log('members after filter', this.filteredMembers);
        });
      });
  }

  ngOnDestroy() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
      if (this.groupSubscription) {
      this.groupSubscription.unsubscribe();
      }
      if (this.orgSeaSub) {
      this.orgSeaSub.unsubscribe();
      }
  }

  invite() {

    if (this.group) {
      console.log('aded to group');
      MeteorObservable.call('group.inviteMembers', this.group._id, this.toAdd).subscribe((sub) => {
        console.log('invite group members', sub);
      });
      MeteorObservable.call('group.removeMembers', this.group._id, this.toRemove).subscribe((sub) => {
        console.log('remove group members', sub);
        // this.viewCtrl.dismiss();
        this.presenters.dismissModal();
      });
    }
    if (this.chat) {
      console.log('aded to chat');
      MeteorObservable.call('chat.inviteMembers', this.chat._id, this.toAdd).subscribe((sub) => {
        console.log('invite chat members', sub);
      });
      MeteorObservable.call('chat.removeMembers', this.chat._id, this.toRemove).subscribe((sub) => {
        console.log('remove chat members', sub);
        // this.viewCtrl.dismiss();
        this.presenters.dismissModal();
      });
    }
    if (this.event) {
      console.log('aded to event');
      if (this.newEvent) {
        // this.viewCtrl.dismiss({members: this.members.filter((mem: any) => mem.selected)});
        this.presenters.dismissModal({members: this.members.filter((mem: any) => mem.selected)});
      } else {
        if (!this.event.repeat) {
          MeteorObservable.call('event.inviteMembers', this.event._id, this.toAdd).subscribe((sub) => {
            console.log('invite event members', sub);
          });
          MeteorObservable.call('event.removeMembers', this.event._id, this.toRemove).subscribe((sub) => {
            console.log('remove event members', sub);
            // this.viewCtrl.dismiss();
            this.presenters.dismissModal();
          });
        } else {
          // const alert = this.alertCtrl.create(
          this.presenters.presentAlert({
            header: 'Confirm Invites',
            message: 'Do you want to change invites for all repeats of this event?',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                }
              },
              {
                text: 'All Repeats',
                handler: () => {
                  MeteorObservable.call('event.inviteRepeatMembers', this.event._id, this.event.repeatId, this.toAdd).subscribe((sub) => {
                    console.log('invite event members', sub);
                  });
                  MeteorObservable.call('event.removeRepeatMembers', this.event._id, this.event.repeatId, this.toRemove).subscribe((sub) => {
                    console.log('remove event members', sub);
                    // this.viewCtrl.dismiss();
                    this.presenters.dismissModal();
                  });
                }
              },
              {
                text: 'Only This',
                handler: () => {
                  console.log('Delete Clicked');
                  MeteorObservable.call('event.inviteMembers', this.event._id, this.toAdd).subscribe((sub) => {
                    console.log('invite event members', sub);
                  });
                  MeteorObservable.call('event.removeMembers', this.event._id, this.toRemove).subscribe((sub) => {
                    console.log('remove event members', sub);
                    // this.viewCtrl.dismiss();
                    this.presenters.dismissModal();
                  });
                }
              }
            ]
          });
        // alert.present();
        }
      }
    }
    if (this.newChat) {
      if (this.toAdd.length >= 2) {
        MeteorObservable.call('chat.newDirect', this.toAdd, this.selectedOrganisation._id).subscribe((sub) => {
          console.log('direct chat', sub);
          // this.close({'chatId':sub});
          // this.viewCtrl.dismiss({'chatId': sub});
          this.presenters.dismissModal({chatId: sub});
        });
      } else {
        // const alert = this.alertCtrl.create(
        this.presenters.presentAlert({
          header: 'Not enough members',
          message: 'You have not chosen anyone to chat with.',
          buttons: ['OK']
        });
      // alert.present();
      }

    }
  }

  addToLists(member) {
    console.log('adding: ', member);
    // member.selected = !member.selected;

    // this.members.find((mem) => mem._id === member._id ).selected = member.selected;
    // console.log(this.members);
    if (member.selected) {
      this.toAdd.push(member._id);

      const index = this.toRemove.indexOf(member._id);
      if (index > -1) {
          this.toRemove.splice(index, 1);
      }
    } else {
      this.toRemove.push(member._id);
      const index = this.toAdd.indexOf(member._id);
      if (index > -1) {
          this.toAdd.splice(index, 1);
      }
    }
    console.log('to Add: ', this.toAdd.length);
    console.log('to Remove: ', this.toRemove.length);
  }

  close() {
    // this.viewCtrl.dismiss();
    this.presenters.dismissModal();
  }

  setFilters() {
    this.filteredMembers = this.searchNamesPipe.transform(this.isInGroupPipe.transform(this.members, this.groupFilter), this.searchInput);
  }
}
